import { BvroboticsapiService } from './../../bvroboticsapi.service';
import { DataService } from '../../data.service';
import { Itemtoshow } from '../../itemtoshow';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {ChangeDetectorRef} from '@angular/core';
import * as xml2js from 'xml2js';




@Component({
  selector: 'app-rvarea',
  templateUrl: './rvarea.component.html',
  styleUrls: ['./rvarea.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class RVareaComponent implements OnInit {

  public now: Date = new Date();
 
  
  items: Itemtoshow[] = [];
  userinterfacemessage:string;
  SenderName:string;
  elementtoDisplay:number;
  message:string;


  constructor(private ref: ChangeDetectorRef, private data:DataService, private bvAPI:BvroboticsapiService) {
    var DefaultItem = new Itemtoshow;
    DefaultItem.localita = 'Configuration ...';
    DefaultItem.description = 'Configuration ...';
    
    DefaultItem.http = 'Configuration ...';
    DefaultItem.itemtype= 'Configuration ...';
    DefaultItem.latitudine='Configuration ...';
    DefaultItem.lobgitudine='Configuration ...';
    DefaultItem.localita='Configuration ...';
    DefaultItem.provincia='Configuration ...';
    DefaultItem.tipo='Configuration ...';
    DefaultItem.tipologia='Configuration ...';
    DefaultItem.mtipo='Configuration ...';

    this.items.push(DefaultItem);
   
    
   }


  ngOnInit() {


    this.bvAPI.getArea().subscribe((data)=>{
      
      
      //let parser = require('xml2js').parseString;
      const parser = new xml2js.Parser({strict: true, trim: true });
      
      
      
      let that = this;
      parser.parseString(data, function(err,result){
        if (err) {
          console.log(err.stack);
        }
        
        var JTranslation = JSON.stringify(result);
        //alert(JTranslation);
        var json = JSON.parse( JTranslation );
         //Empty the array
         while (that.items.length>0) { that.items.pop(); }

         json.GetRVData.RVElementAry[0].Element.forEach(element => {
         
            var areaDaMostrare = new Itemtoshow();
            areaDaMostrare.description = element.descrizione;
           
            areaDaMostrare.http = element.http;
            areaDaMostrare.itemtype= element.tipologia;
            areaDaMostrare.latitudine=element.latitudine;
            areaDaMostrare.lobgitudine=element.longitudine;
            areaDaMostrare.localita=element.località;
            areaDaMostrare.provincia=element.provincia;
            areaDaMostrare.tipo=element.Atipo;
            areaDaMostrare.tipologia=element.cTipo;
            areaDaMostrare.mtipo=element.mTipo;
         
            that.items.push(areaDaMostrare);
           
         });
        
         
      });

      this.ref.detectChanges();
    });
   

    this.data.currentMessage.subscribe(message => {this.message = message ; this.manageTheMessage(this.message);})
 
  }


  manageTheMessage(msg:string)
  {
    
    try {
      var json = JSON.parse( msg );
      //Empty the array
      while (this.items.length>0) { this.items.pop(); }
      var areaIndex:number = 0;
      json.GetRVData.RVElementAry[0].Element.forEach(element => {
         
        var areaDaMostrare = new Itemtoshow();
        areaDaMostrare.description = element.descrizione;
        if(element.latitudine!='') {
          areaDaMostrare.indice = areaIndex; 
          areaIndex++;
        }  
        areaDaMostrare.http = element.http;
        areaDaMostrare.itemtype= element.tipologia;
        areaDaMostrare.latitudine=element.latitudine;
        areaDaMostrare.lobgitudine=element.longitudine;
        areaDaMostrare.localita=element.località;
        areaDaMostrare.provincia=element.provincia;
        areaDaMostrare.tipo=element.Atipo;
        areaDaMostrare.tipologia=element.cTipo;
        areaDaMostrare.mtipo=element.mTipo;
         
        
        this.items.push(areaDaMostrare);
           
      });
      //this.ref.detectChanges();
    }
    catch(e) {
      ; //Do nothing just not lose the observable object
        //alert(e); // error in the above string (in this case, yes)!
    }
    
    
 
  }

  public ngAfterViewInit() {
    
    this.ref.detectChanges();
  }

  GetFullMap(latitude,longitude) {
    
    //alert('Changing the message');
    this.data.changeMessage('CENTRA:' + latitude + ';' + longitude);
    
  }

  SearchRouteTo(latitude,longitude) {
    
    //alert('Changing the message');
    this.data.changeMessage('ROUTE:' + latitude + ';' + longitude);
    
  } 

}
