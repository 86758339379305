import { GroupElement } from './../app/group-element';
import { Menucomponents } from './../app/model/menucomponents';

export const MENUELEMENTS: Menucomponents[] = [

        {idMenu:1,id:0,href:'http://www.bvrobotics.com/RVPlanner.html',desc:'Usare il modulo RVPlanner di Buonevacanze.org'},
        {idMenu:1,id:1,href:'http://www.bvrobotics.com/miniservices1.0/SignUpProcedure.html',desc:'Create an user for the Miniservice infrastructure'},
        {idMenu:1,id:2,href:'http://www.bvrobotics.com/EndStopsConfig.html',desc:'Configure 3DPrinter Endstops'},
        {idMenu:1,id:3,href:'http://www.bvrobotics.com/Multidimensional.html',desc:'Use the BLE protocol'},
        {idMenu:1,id:4,href:'http://www.bvrobotics.com/GPS_Accellerometer.html',desc:'Use a GPS device connected with Arduino'},
        {idMenu:1,id:5,href:'http://www.bvrobotics.com/SegWayFunc.html',desc:'Build an autobalancer algorithm'},
        {idMenu:1,id:6,href:'http://www.bvrobotics.com/FollowMeFunc.html',desc:'Build a FollowMeFunction using Arduino and a PixieCam'},
        {idMenu:1,id:7,href:'http://www.bvrobotics.com/GPS_Accellerometer.html',desc:'Install, configure and drive an OLED display'},
        {idMenu:1,id:8,href:'http://www.bvrobotics.com/ConfigureArduinoLibrary.html',desc:'Configure the Arduino 101 Curie IMU library'},
        {idMenu:1,id:9,href:'http://www.bvrobotics.com/Multidimensional.html',desc:'Use the BLE protocol'},
        {idMenu:1,id:10,href:'http://www.bvrobotics.com/HCSR04.html',desc:'Configure the HCSR04 Sonic Sensor in Arduino'},
        {idMenu:1,id:11,href:'http://www.bvrobotics.com/Potentiometer.',desc:'Use a potentiometer with Arduino'},
        {idMenu:1,id:12,href:'http://www.bvrobotics.com/Zprobe.html',desc:'Configure and install a Zprobe on a Delta 3d Printer'},
        {idMenu:1,id:13,href:'http://www.bvrobotics.com/SevenSegs.html',desc:'use more than one seven segments display with Arduino'},
        {idMenu:1,id:14,href:'http://www.bvrobotics.com/miniservices1.0/UploadFirmwareNodeMCUConnections.html',desc:'Build build your first IoT home automation device'},


        {idMenu:0,id:0,href:'https://www.buonevacanze.org/console.aspx',desc:'GadgetConsole'},
        {idMenu:0,id:1,href:'https://www.thingiverse.com/Vitmar_2000/designs',desc: 'Thinghiverse objects'},
        {idMenu:0,id:2,href:'http://www.buonevacanze.org/approver/default.aspx',desc:'Approver REST'},
        {idMenu:0,id:3,href:'http://www.buonevacanze.org/approver/Georeference.aspx',desc:'GeoReference System'},
        {idMenu:0,id:4,href:'http://www.buonevacanze.org/approver/configit.aspx',desc:'Configuration Download'},
        {idMenu:0,id:5,href:'http://www.bvrobotics.com/DownloadPage.html',desc:'Download Page'},
 
        {idMenu:2,id:0,href:'http://www.bvrobotics.com/3dPrinting.html',desc:'How 3d printing technology works'},
        {idMenu:2,id:1,href:'http://www.bvrobotics.com/ArduinoDevEnvironment.html',desc:'The Arduino Development Framework'},
        {idMenu:2,id:2,href:'http://www.bvrobotics.com/REST.html',desc:'How REST functions work'},
        {idMenu:2,id:3,href:'http://www.bvrobotics.com/WhatYouCanDo.html',desc:'What you can do with IoT'},
        {idMenu:2,id:4,href:'http://www.bvrobotics.com/WhatYouCanDoMS.html',desc:'What you can do with Miniservices'},
        {idMenu:2,id:5,href:'http://www.bvrobotics.com/Miniservices1.0/UploadFirmwareNodeMCUFirmware.html',desc:'Configure NodeMCU on arduino DevEnv'}

]; 

export const GROUPELEMENTS:GroupElement[] = [
        
        {url:'https://www.bvrobotics.com/media/RV/CamperChat/Frontale.jpg',name:'Main Group',selected:false},
        {url:'https://www.bvrobotics.com/media/RV/CamperChat/AreaSosta.jpg',name:'Aree Sosta',selected:false},
        {url:'https://www.bvrobotics.com/media/RV/CamperChat/Accessori.jpg',name:'Accessori',selected:false},
        {url:'https://www.bvrobotics.com/media/RV/CamperChat/Meccanica.jpg',name:'Meccanica',selected:false},
        {url:'https://www.bvrobotics.com/media/RV/CamperChat/Private.jpg',name:'Private',selected:false}];
        