import { MapdialogComponent } from './../mapdialog/mapdialog.component';

import { Itemtoshow } from './../../itemtoshow';
import { Chatmessage } from './../chatmessage';

import { Component, OnInit, Input, Inject } from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { BvroboticsapiService } from './../../bvroboticsapi.service';
import * as xml2js from 'xml2js';


@Component({
  selector: 'app-chatcomponent',
  templateUrl: './chatcomponent.component.html',
  styleUrls: ['./chatcomponent.component.css']
})
export class ChatcomponentComponent implements OnInit {

  @Input() username:string;
  @Input() groupselected:string;
  @Input() groupname:string;



  
  public now: Date = new Date();
  DecreaseConstant: number = 1;
  messages: Chatmessage[] = [];
  
  items: Itemtoshow[] = [];
  userinterfacemessage:string;
  SenderName:string;
  

  constructor(public dialog:MatDialog, private bvAPI:BvroboticsapiService,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {

    //retrieve the previous list of messages
    this.messages = JSON.parse(localStorage.getItem('Messages'+this.groupname.split(' ').join('-')) || '[]');

 //Delete from messages Robotics Postmaster entry
 for( var i = 0; i < this.messages.length; i++){
      
  if(this.messages[i].sender === 'Robotic Postmaster') {
  this.messages.splice(i, 1); 
  i--;
  }  

}

    if(this.groupname == 'Main Group') {
      if(this.username == null)  {   

        var msgtodisplay = new Chatmessage('Robotic Postmaster',"Benvenuto in Buonevacanze.org. Non sei autenticato. Richiedi un account gratuito o utilizza solo i servizi base",this.now,'Main Group',null);
        this.messages.push(msgtodisplay);
      }
      else {
        var msgtodisplay = new Chatmessage('Robotic Postmaster',"Ciao "+this.username + ", bentornato su Buonevacanze.org. scrivi --help per aiuto sui comandi. Altimenti cerca un area o visualizza un articolo",this.now,'Main Group',null);
        this.messages.push(msgtodisplay);
      }
    }
    

   

    //TIMER:TIMER:TIMER
    //Set the function to read messages (every 5 secs)
    setInterval(function( grpName, that) { 
      
      
      if(grpName == 'Main Group')
        this.DecreaseConstant = 2;
      
      that.bvAPI.getMessage(grpName, that.messages.length-this.DecreaseConstant).subscribe((data)=>{
        
        //let parser = require('xml2js').parseString;
        const parser = new xml2js.Parser({strict: true, trim: true });
        
        //let that = this;
        parser.parseString(data, function(err,result){
          if (err) {
            console.log(err.stack);
          }
          
          var JTranslation = JSON.stringify(result);
          
          //alert(JTranslation);
          var json = JSON.parse( JTranslation );
          
          //Date received
          that.now = new Date();
          
          try {
            if(json.GetMessage.ReturnMessage != 'No Raws retrieved') {
              json.GetMessage.MSGElementAry[0].Element.forEach(element => {
            
                that.SenderName = element.Author;
                var theMessage = element.Message;
                if(that.SenderName == 'Robotic Configurator:' + that.username) {
                  var locationJString = JSON.parse(theMessage);
  
                  locationJString.GetRVData.RVElementAry[0].Element.forEach(element => {
                      
                    var areaDaMostrare = new Itemtoshow();
                    areaDaMostrare.description = element.descrizione;
                    areaDaMostrare.http = element.httptesto;
                    areaDaMostrare.itemtype= element.tipologia;
                    areaDaMostrare.latitudine=element.latitudine;
                    areaDaMostrare.lobgitudine=element.longitudine;
                    areaDaMostrare.localita=element.località;
                    areaDaMostrare.provincia=element.provincia;
                    areaDaMostrare.tipo=element.Atipo;
                    areaDaMostrare.tipologia=element.cTipo;
                    areaDaMostrare.mtipo=element.mTipo;
                
                    that.items.push(areaDaMostrare);
                
                  });
  
                  var msgtodisplay = new Chatmessage(null,theMessage,that.now,that.groupname,that.items);
  
  
                }
                else {
                  //Prepare the message object
                  var msgtodisplay = new Chatmessage(that.SenderName,theMessage,that.now,that.groupname,null);
                }
                if (that.SenderName != 'Robotic Configurator'){
                    
                  //Push The message into the list only if it is not a configuration message
                  that.messages.push(msgtodisplay);
    
                  localStorage.setItem('Messages'+that.groupname.split(' ').join('-'), JSON.stringify(that.messages));
                }
              });
            }
          }
          catch(e) {
            //Empty all the lists and the storage (Start from scratch because of an error)
              
              localStorage.removeItem('Messages'+grpName.split(' ').join('-'));
              that.ClearMessageList();
          
          }
        });
    
      });
  
    }
    
    
    
    , 15000,this.groupname, this);  
     
  }

  
  CheckMessage() {
  
    
      if(this.groupname == 'Main Group')
        this.DecreaseConstant = 2;

    this.bvAPI.getMessage(this.groupname, this.messages.length-this.DecreaseConstant).subscribe((data)=>{
        
      //let parser = require('xml2js').parseString;
      const parser = new xml2js.Parser({strict: true, trim: true });
      
      let that = this;
      parser.parseString(data, function(err,result){
        if (err) {
          console.log(err.stack);
        }
        
        var JTranslation = JSON.stringify(result);
        
        //alert(JTranslation);
        var json = JSON.parse( JTranslation );
        
        //Date received
        that.now = new Date();
        try  {
          if(json.GetMessage.ReturnMessage != 'No Raws retrieved') {
            json.GetMessage.MSGElementAry[0].Element.forEach(element => {
          
              that.SenderName = element.Author;
              var theMessage = element.Message;
              if(that.SenderName == 'Robotic Configurator:' + that.username) {
                var locationJString = JSON.parse(theMessage);

                locationJString.GetRVData.RVElementAry[0].Element.forEach(element => {
                    
                  var areaDaMostrare = new Itemtoshow();
                  areaDaMostrare.description = element.descrizione;
                  areaDaMostrare.http = element.httptesto;
                  areaDaMostrare.itemtype= element.tipologia;
                  areaDaMostrare.latitudine=element.latitudine;
                  areaDaMostrare.lobgitudine=element.longitudine;
                  areaDaMostrare.localita=element.località;
                  areaDaMostrare.provincia=element.provincia;
                  areaDaMostrare.tipo=element.Atipo;
                  areaDaMostrare.tipologia=element.cTipo;
                  areaDaMostrare.mtipo=element.mTipo;
              
                  that.items.push(areaDaMostrare);
              
                });

                var msgtodisplay = new Chatmessage(null,theMessage,that.now,that.groupname,that.items);


              }
              else {
                //Prepare the message object
                var msgtodisplay = new Chatmessage(that.SenderName,theMessage,that.now,that.groupname,null);
              }
              if (that.SenderName != 'Robotic Configurator'){
                  
                //Push The message into the list only if it is not a configuration message
                that.messages.push(msgtodisplay);
  
                localStorage.setItem('Messages'+that.groupname.split(' ').join('-'), JSON.stringify(that.messages));
              }
            });
          }
        }
      
        catch(e) {
          //empty the storage
          localStorage.removeItem('Messages'+that.groupname.split(' ').join('-'));
          that.ClearMessageList();
        }
      });
    
  
    });


  }


  GetFullMap(latitude,longitude) {


 
    
    //send Latitude and Longitude
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {latitude: latitude, longitude: longitude}
    let dialogRef = this.dialog.open(MapdialogComponent, dialogConfig);

  }

  ClearMessageList() {
    
    //Empty the array
    while (this.messages.length) { this.messages.pop(); }
    
  }
  goToUrl(url): void {
    var uri = encodeURI(url);
    uri = uri.replace('#','');
    
    if((uri.indexOf('http:')>-1)||(uri.indexOf('https:')>-1)){
        this.document.location.href = uri;
      }
    else{
      this.document.location.href = 'https://'+uri;
      }
}

}
