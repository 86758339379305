import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse  } from '@angular/common/http';
import {  throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import * as xml2js from 'xml2js';




@Injectable({
  providedIn: 'root'
})
export class BvroboticsapiService {

  public PROD_SERVR = "https://www.buonevacanze.org/approver/";
  public DEV_SERVR = "http://localhost:50646/";

  public GET_AREA_API = "GetRVData.aspx?Action=Random&Location=NODEF";
  public GET_LOCATION_LIST = "GetRVData.aspx?Action=LocList&Location=NODEF";
  public GET_REVIEW_LIST = "GetRVData.aspx?Action=ReviewList&Location=NODEF";
  public GET_LOCATION_API = "GetRVData.aspx?Action=Area&Location=";
  public GET_NEAREST_AREAS = "GetNearestArea.aspx?"
  public AI_SEARCH_API = "AIMatching.aspx?Message=";

  //This variable is needed to update all rest API in one shoot
  private CUR_SERVR = this.PROD_SERVR;
 
  constructor(private httpClient: HttpClient) { }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage); //I am not trowing because otherwise an alert will apear to the user
    return throwError(errorMessage);
  }

  public getArea(){
    
    return this.httpClient.get(this.CUR_SERVR + this.GET_AREA_API, {responseType: 'text'}).pipe(catchError(this.handleError));;
  
  }

  public getLocationList(){
    
    return this.httpClient.get(this.CUR_SERVR + this.GET_LOCATION_LIST, {responseType: 'text'}).pipe(catchError(this.handleError));;
  
  }

  public getReviewList(){
    
    return this.httpClient.get(this.CUR_SERVR + this. GET_REVIEW_LIST, {responseType: 'text'}).pipe(catchError(this.handleError));;
  
  }

  public sendMail(to, Subject, message) {
    
  
    var url = 'EmailSender.aspx?TO='+to+'&SUBJECT='+Subject+'&MESSAGE='+message+'&TOKEN=FVJVJJYTFJYTF'
    var uri = encodeURI(url);
    
    return this.httpClient.get(this.CUR_SERVR + uri, {responseType: 'text'}).pipe(catchError(this.handleError));;
    //nothing to return
    
  }
  public sendMessage(message,group,username) {
    
  
    var url = 'RemarkManager.aspx?CALLER='+group+'&AUTHOR='+username+'&MESSAGE='+message+'&NUMBER=999&SEQUENCE=999&RELATION=999'
    var uri = encodeURI(url);
    
    return this.httpClient.get(this.CUR_SERVR + uri, {responseType: 'text'}).pipe(catchError(this.handleError));;
    //nothing to return
    
  }

  public getMessage(group,myLast) {
    
    //alert("sono dentro e provo ad accedere");
    var url = 'GetMessage.aspx?GROUP='+ group + '&MYLAST='+myLast;
    var uri = encodeURI(url);
    //alert('requesting:' + this.CUR_SERVR + uri );
    return this.httpClient.get(this.CUR_SERVR + uri, {responseType: 'text'}).pipe(catchError(this.handleError));;
    
    
  }
  
  public getSpecificArticle(id){
    
    var url = 'GetRVData.aspx?Action=Article&ID=' + id;
    return this.httpClient.get(this.CUR_SERVR + url, {responseType: 'text'}).pipe(catchError(this.handleError));;
  
  }

  public manageArticleConfiguration(message)
  {

    if (message == 'mainnews')
    var Articleurl = 'GetRVData.aspx?Action=Article&ID=NWRandom'; //Location is not used but can be adapted for future use
  else
    var Articleurl = 'GetRVData.aspx?Action=Article&ID=Random';
    
    return this.httpClient.get(this.CUR_SERVR + Articleurl, {responseType: 'text'}).pipe(catchError(this.handleError));;
  
  }

  public getArticleList()
  {

    
    var Articleurl = 'GetRVData.aspx?Action=ArticleList&Location=any';
    
    return this.httpClient.get(this.CUR_SERVR + Articleurl, {responseType: 'text'}).pipe(catchError(this.handleError));;
  
  }

  getNearestAreas(latitude,longitude,radius) {

     return this.httpClient.get(this.CUR_SERVR + this.GET_NEAREST_AREAS + 'LATITUDE=' + latitude + '&LONGITUDE=' + longitude + '&RADIUS=' + radius, {responseType: 'text'}).pipe(catchError(this.handleError));;
  
  }

  public getSpecificArea(location:string){
    
    return this.httpClient.get(this.CUR_SERVR + this.GET_LOCATION_API + location, {responseType: 'text'}).pipe(catchError(this.handleError));;
  
  }

  public getSpecificAPI(api:string)
  {
    
    return this.httpClient.get(this.CUR_SERVR + api, {responseType: 'text'}).pipe(catchError(this.handleError));;
  
  }

  public searchAreaUsingAI(message)
  {
    var url = this.CUR_SERVR + this.AI_SEARCH_API + message + '&Category=AreaSearch';
    var uri = encodeURI(url);
    return this.httpClient.get(uri,{responseType: 'text'}).pipe(catchError(this.handleError));
  
  }

  public getIPAddress()
  {
    return this.httpClient.get("https://api.ipify.org/?format=json", {responseType: 'text'}).pipe(catchError(this.handleError));;
  }
}


