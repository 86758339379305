import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-rvplaner',
  templateUrl: './rvplaner.component.html',
  styleUrls: ['./rvplaner.component.css']
})
export class RvplanerComponent implements OnInit {

  public start: string;
    public finish: string;
    public title:string

    public constructor() {
        

        this.start = "41.9249,15.8499";
        this.finish = "41.9204,15.8374";
    }

    public ngOnInit() {
      this.title='camper-routes';
     }

    public ngAfterViewInit() {}
    
    

}
