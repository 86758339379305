<ul class="list-group list-group-messaggi" id="messageContainer">
  <li *ngFor="let item of items;" style="padding: 2px 2px">
    <mat-card class="menu-card" style="width:100%;">
      <mat-card-header>
        <div *ngIf="(item.latitudine!='')&&(item.indice!=99)">
          <svg  width="24" height="24" xmlns="http://www.w3.org/2000/svg">
            <rect stroke="white" fill="#1b468d" x="1" y="1" width="22" height="22" />
            <text x="13" y="15" font-size="8pt" font-family="Arial" font-weight="bold" text-anchor="middle" fill="white">
              {{item.indice}}
            </text>
          </svg>
        </div>



        
        <img mat-card-avatar class="menu-header-image" src="https://www.bvrobotics.com/media/RV/CamperChat/{{item.itemtype}}.jpg">
        <mat-card-title>{{item.localita}}</mat-card-title>
        <mat-card-subtitle>{{item.tipologia}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
  
        {{item.description}}

      </mat-card-content>
      <mat-card-actions>
        <button mat-button *ngIf="item.latitudine!=''" (click)="GetFullMap(item.latitudine,item.lobgitudine)">CENTER ON THE MAP</button>
        <button mat-button *ngIf="item.latitudine!=''" (click)="SearchRouteTo(item.latitudine,item.lobgitudine)">EVALUATE ROUTE</button>
      </mat-card-actions>
    </mat-card>
  </li>
</ul>