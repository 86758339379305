
import { Menucomponents } from './../model/menucomponents';
import { Component, OnInit, Input } from '@angular/core';



@Component({
  selector: 'app-button-menu-top-center',
  templateUrl: './button-menu-top-center.component.html',
  styleUrls: ['./button-menu-top-center.component.css']
})
export class ButtonMenuTopCenterComponent implements OnInit {
  
  
  //input from the main
  @Input() MnuElements:Menucomponents[];
  @Input() ButtonTitle:string;

  constructor() { 
   
    
  }

  ngOnInit(): void {
  }
  dettaglio(id) {
    
  }
}
