<form class="example-form" #f="ngForm" (ngSubmit)="onSubmit(f)" novalidate>
    
  
    <table class="example-full-width" cellspacing="0">
        <tr>
            <td>   
                <mat-form-field class="example-full-width" >
                    <mat-label>Località</mat-label>
                    <input matInput maxlength="50" name="localita" ngModel required #localita="ngModel">
                    
                </mat-form-field>
            </td>
            <td>
                <mat-form-field class="example-full-width">
                    <mat-label>Provincia</mat-label>
                    <input matInput maxlength="2" name="provincia" ngModel required #provincia="ngModel">
                </mat-form-field>
            </td>
        </tr>
    </table>
  
    <p>
      <mat-form-field class="example-full-width">
        <mat-label>Descrizione</mat-label>
        <textarea matInput placeholder="Ex. Area di sosta sita in via ..." name="descrizione" ngModel required #descrizione="ngModel"></textarea>
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label>Sito Web</mat-label>
        <textarea matInput maxlength="50" name="http" ngModel required #http="ngModel"></textarea>
      </mat-form-field>
    </p>
  
    <table class="example-full-width" cellspacing="0">
        <tr>
            <td>   
                <mat-form-field class="example-full-width">
                    <mat-label>Latitudine</mat-label>
                    <input matInput maxlength="10" name="latitudine" ngModel required #latitudine="ngModel">
                    
                </mat-form-field>
            </td>
            <td>
                <mat-form-field class="example-full-width">
                    <mat-label>Longitudine</mat-label>
                    <input matInput maxlength="10" name="longitudine" ngModel required #longitudine="ngModel">
                </mat-form-field>
            </td>
        </tr>
    </table>
  
  <p></p>
  <button mat-button style="color: white;">INVIA</button>
  <p></p>
</form>
