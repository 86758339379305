<div *ngIf="((this.BoxID == 'mainnews')|| (this.BoxID == 'centerleft')||(this.BoxID == 'centerright')); else ListaArticoli">

  <mat-card class="menu-card">
    <mat-card-header>
    
      <img mat-card-avatar *ngIf="!this.IsNews; else newsIMGBlock" class="menu-header-image" src="https://www.bvrobotics.com/media/RV/CamperChat/localita/{{titolo + '_main'}}.jpg">
      <ng-template #newsIMGBlock>
        <img mat-card-avatar class="menu-header-image" src="https://www.bvrobotics.com/media/RV/CamperChat/news.jpg">
      </ng-template>
      <mat-card-title>{{titolo}}</mat-card-title>
      <mat-card-subtitle *ngIf="!this.IsNews">{{sottotitolo}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p></p>
        {{articoloBreve}}...

  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="GetFullArticle()">MOSTRA TUTTO</button>
    <button mat-button *ngIf="!this.IsNews" (click)="SearchRVLocation(titolo)">CERCA AREA SOSTA</button>
    <button mat-button *ngIf="this.IsNews" (click)="gotoArticles()">I NOSTRI ARTICOLI</button>
  </mat-card-actions>
  </mat-card>
</div>


<ng-template #ListaArticoli>
  <div *ngFor="let article of ArticlesList">
    <mat-card class="menu-card">
      <mat-card-header>
        <img mat-card-avatar class="menu-header-image" src="https://www.bvrobotics.com/media/RV/CamperChat/news.jpg">
        <mat-card-title>{{article.titolo}}</mat-card-title>
        <!--<mat-card-subtitle *ngIf="!this.IsNews">{{article.sottotitolo}}</mat-card-subtitle>-->
      </mat-card-header>
      <mat-card-content>
        <p></p>
          {{article.articoloBreve}}...

      </mat-card-content>
    <mat-card-actions>
      <button mat-button (click)="GetDetailArticle(article.CodArticolo)">MOSTRA TUTTO</button>
    </mat-card-actions>
    </mat-card>
    <p></p>
  </div>
</ng-template>