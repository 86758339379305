
import { Component, OnInit} from '@angular/core';
import { NgForm } from '@angular/forms';
import { BvroboticsapiService } from './../../bvroboticsapi.service';
import * as xml2js from 'xml2js';


@Component({
  selector: 'app-addyourarea',
  templateUrl: './addyourarea.component.html',
  styleUrls: ['./addyourarea.component.css']
})



export class AddyourareaComponent implements OnInit {
  localita:string;
  
  

  constructor(private bvAPI:BvroboticsapiService) { }

  ngOnInit(): void {
  }

  onSubmit(f: NgForm){ 

    //Chanage in Uppercase
    f.value.provincia =f.value.provincia.toUpperCase();

    var email = 'this is a test essage to add an area for. Località:' + f.value.localita +
                '\n\rProvincia:' + f.value.provincia +
                '\n\rDescrizione:' + f.value.descrizione +
                '\n\rSito:' + f.value.http +
                '\n\rLatitudine:' + f.value.latitudine +
                '\r\nLongitudine:' + f.value.longitudine;
    
    var Observer = this.bvAPI.sendMail('vittorio.margherita@buonevacanze.org', 'new area proposed', email).subscribe((data)=>{ /*Do nothing*/ });

    //say thanks
    alert('Grazie per aver inviato la richiesta. Verrà presa in considerazione nel più breve tempo possibile');

    f.reset();
    

  }
}
