import { BvroboticsapiService } from './../../bvroboticsapi.service';
import { Subscription } from 'rxjs';
import { stringify } from 'querystring';
import { DataService } from './../../data.service';

import { Itemtoshow } from './../../itemtoshow';
import { Component, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef, Input } from '@angular/core';
import {FormControl} from '@angular/forms';
import { NgForm } from '@angular/forms';

import {ChangeDetectorRef} from '@angular/core'
import { Location } from '@angular/common';
import * as xml2js from 'xml2js';




declare var H: any;
//declare var routeTo:string;


@Component({
    selector: 'here-map',
    templateUrl: './here-map.component.html',
    styleUrls: ['./here-map.component.css']
})
export class HereMapComponent implements OnInit {


    myLat:string = 'NoDef';
    myLon:string = 'NoDef';
    routeTo:string = 'NoDef';
    veichle:string = 'car';

    //Timeout for the navigation
    navigationTimeout = 5000;
    //disableSelect = new FormControl(false);

    @ViewChild("map")
    public mapElement: ElementRef;

    @Input()
    public appId: any;

    @Input()
    public appCode: any;

    @Input()
    public start: any;

    @Input()
    public finish: any;

    @Input()
    public width: any;

    @Input()
    public height: any;

    directions:string[] = [];
  

    private platform: any;
    private map: any;
    private router: any;
    public herejason:string;
    private PlaceHolderPlaced:boolean=false;

    items: Itemtoshow[] = [];
    targetElement:any;
    defaultLayers:any;
    message:string;
    ui:any;
    navTimer:any;
    navTimeout:number;
    pathDrown:boolean = false;
    duringNavMarker:any;
    

    svgMarkup:string = '<svg width="24" height="24" ' +
    'xmlns="http://www.w3.org/2000/svg">' +
    '<rect stroke="white" fill="#1b468d" x="1" y="1" width="22" ' +
    'height="22" /><text x="13" y="15" font-size="8pt" ' +
    'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
    'fill="white">ME</text></svg>';
    

    public constructor(private data:DataService, private ref: ChangeDetectorRef, private bvAPI:BvroboticsapiService) { 

        
    }


    public ngOnInit() {
      this.platform = new H.service.Platform({
        'apikey': 'tfrc343AbP8CCjd2PKNbJHp80sugVeXNMZxF5vi8kMk'
      });
      

      
      this.router = this.platform.getRoutingService(null, 8);

      this.data.currentMessage.subscribe(message => { this.message = message; this.MoveTheMap(this.message); })

  }

  MoveTheMap(msg:string)
  {
    //alert(this.message);
    if (msg != 'InitMSG') {
      if(msg.indexOf('CENTRA:')>-1) {
      var lat = msg.substr(7,msg.indexOf(';')-7);
      var lon = msg.substr(msg.indexOf(';') + 1, msg.length-lat.length-8);
      this.map.setCenter({lat:lat, lng:lon});
      this.map.setZoom(13);
      if(this.PlaceHolderPlaced == false)
        this.map.addObject(new H.map.Marker({lat:lat, lng:lon}))
    }
    else if(msg.indexOf('ROUTE:')>-1) {
      this.routeTo = msg;
      if((this.myLat == 'NoDef')||(this.myLon == 'NoDef'))
        alert('Please retrieve your current location first');
      else{
        this.route();
      }


     
    }
  }

  }

  IconWithNumber(index) {
    var Marker:string = '<svg width="24" height="24" ' +
      'xmlns="http://www.w3.org/2000/svg">' +
      '<rect stroke="white" fill="#1b468d" x="1" y="1" width="22" ' +
      'height="22" /><text x="13" y="15" font-size="8pt" ' +
      'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
      'fill="white">' + index +'</text></svg>';
      return Marker;
  }

  StartNavigating() {
    
    if(this.pathDrown == true)  {
      this.navTimeout = this.navigationTimeout;
      this.navTimer = setInterval(this.ShowPosOnMapduringNav, 5000, this);
    }
    else alert('Percorso non ancora definito');
  
  }

  StopNavigating() {
    
    this.navTimeout = 1;
    clearInterval(this.navTimer);
  
  }

  ShowPosOnMapduringNav(that) {
    
     
      that.navTimeout--;
      if(that.navTimeout == 0)
        clearInterval(this.navTimer);
      else {
        that.map.setCenter({lat:that.myLat, lng:that.myLon});
        that.map.setZoom(18);
        var icon = new H.map.Icon(that.svgMarkup);
        this.map.removeObjects(this.duringNavMarker);
        this.duringNavMarker = new H.map.Marker({
          lat: that.myLat,
          lng: that.myLon
        }, {icon: icon});
        that.map.addObject(this.duringNavMarker);
      }

  }

  ShowPosOnMap() {
    
    
    if((this.myLat == 'NoDef')||(this.myLon == 'NoDef')){
      
      //get the current position
      this.GetCurrPosAction().then(pos=>
        {
          //alert(`Positon: ${pos.lng} ${pos.lat}`);
          this.myLat = pos.lat;
          this.myLon = pos.lng;
          this.map.setCenter({lat:this.myLat, lng:this.myLon});
          this.map.setZoom(13);
          var icon = new H.map.Icon(this.svgMarkup);
          this.map.removeObjects(this.map.getObjects());
    
          //this.map.addObject(new H.map.Marker({lat:this.myLat, lng:this.myLon}))
    
          var CamperMarker = new H.map.Marker({
            lat: this.myLat,
            lng: this.myLon
          }, {icon: icon});
          this.map.addObject(CamperMarker);
          
        });
        
    }
    else {
      alert('cerco la poszione');
      this.map.setCenter({lat:this.myLat, lng:this.myLon});
      this.map.setZoom(13);
      var icon = new H.map.Icon(this.svgMarkup);
      this.map.removeObjects(this.map.getObjects());

      //this.map.addObject(new H.map.Marker({lat:this.myLat, lng:this.myLon}))

      var CamperMarker = new H.map.Marker({
        lat: this.myLat,
        lng: this.myLon
      }, {icon: icon});
      this.map.addObject(CamperMarker);




      
        
    }
    
    
    
  }


  GetCurrPosAction(): Promise<any>
  {
    return new Promise((resolve, reject) => {

      /*//navigator.geolocation.WhatchPosition    (Use this if you want constantly monitor the user position)
      navigator.geolocation.getCurrentPosition(resp => {

          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });*/

        var watchID = navigator.geolocation.watchPosition( resp => {

          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
        var timeout = setTimeout( function() { navigator.geolocation.clearWatch( watchID ); }, 5000 );
       
       

        });

  }
  
  GetCurrPos() {
    this.GetCurrPosAction().then(pos=>
      {
         //alert(`Positon: ${pos.lng} ${pos.lat}`);
         this.myLat = pos.lat;
         this.myLon = pos.lng;
         
      });
    //navigator.geolocation.getCurrentPosition(this.ShowPos);
  }

  onSubmit(f: NgForm){ 

    //alert(f.value.myPos);
    //alert(f.value.AreaLoc);
    //alert(f.value.moveBy);
    //Chanage in Uppercase
    //f.value.provincia =f.value.provincia.toUpperCase();

    this.SearchRVLocation(f.value.AreaLoc);

    //f.reset();
    

  }
  
  GetNearestArea() {

    

    // optionally - resize a larger PNG image to a specific size
    var pngIcon = new H.map.Icon("https://www.bvrobotics.com/media/RV/CamperChat/MAPRV.png", {size: {w: 56, h: 56}});

    /*var svgMarkup = '<svg width="24" height="24" ' +
    'xmlns="http://www.w3.org/2000/svg">' +
    '<rect stroke="white" fill="#1b468d" x="1" y="1" width="22" ' +
    'height="22" /><text x="13" y="15" font-size="8pt" ' +
    'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
    'fill="white">ME</text></svg>';
*/
    var icon = new H.map.Icon(this.svgMarkup);

    //If the current position is not yet retrieved, retrieve it
    if((this.myLat != 'NoDef')||(this.myLon != 'NoDef')){

      //Remove objects from the map
      this.map.removeObjects(this.map.getObjects());

      //this.map.addObject(new H.map.Marker({lat:this.myLat, lng:this.myLon}))

      var CamperMarker = new H.map.Marker({
        lat: this.myLat,
        lng: this.myLon
      }, {icon: icon});
      this.map.addObject(CamperMarker);
       
    }
    else{
      alert("Devi prima recuperare la tua posizione");
      return;
    }

    this.bvAPI.getNearestAreas(this.myLat,this.myLon,'0.07').subscribe((data)=>{
       
       const parser = new xml2js.Parser({strict: true, trim: true });
      
      
      
       let that = this;
       parser.parseString(data, function(err,result){
         if (err) {
           console.log(err.stack);
         }
         
         var JTranslation = JSON.stringify(result);
 
         //and now pass the JSON string to the area object
         that.data.changeMessage(JTranslation);
         
         var json = JSON.parse( JTranslation );
          //Empty the array
          while (that.items.length>0) { that.items.pop(); }
          var areaIndex: number = 0;
          json.GetRVData.RVElementAry[0].Element.forEach(element => {
          
             var areaDaMostrare = new Itemtoshow();
             if(element.latitudine !='') {
                areaDaMostrare.indice = areaIndex;
             }
             areaDaMostrare.description = element.descrizione;
            
             areaDaMostrare.http = element.http;
             areaDaMostrare.itemtype= element.tipologia;
             areaDaMostrare.latitudine=element.latitudine;
             areaDaMostrare.lobgitudine=element.longitudine;
             areaDaMostrare.localita=element.località;
             areaDaMostrare.provincia=element.provincia;
             areaDaMostrare.tipo=element.Atipo;
             areaDaMostrare.tipologia=element.cTipo;
             areaDaMostrare.mtipo=element.mTipo;
          
             that.items.push(areaDaMostrare);
             
 
             if(element.latitudine !='') {
                //that.map.addObject(new H.map.Marker({lat:element.latitudine, lng:element.longitudine}))
                var Areaicon = new H.map.Icon(that.IconWithNumber(areaIndex));
                var AreaMarker = new H.map.Marker({
                  lat: element.latitudine,
                  lng: element.longitudine
                }, {icon: Areaicon});
                that.map.addObject(AreaMarker);
                that.PlaceHolderPlaced = true;
                
                areaIndex++;

  
               }
 
           });

           //Add the bubbles (for now this is removed)
           //that.addInfoBubble(that.map, JTranslation);
 
           that.map.setCenter({lat:that.myLat, lng:that.myLon});
           that.map.setZoom(11);
         
          
       });
 
       this.ref.detectChanges();
     });
  }



  addMarkerToGroup(group, coordinate, html) {
    var marker = new H.map.Marker(coordinate);
    // add custom data to the marker
    marker.setData(html);
    group.addObject(marker);
  }

  addInfoBubble(map, JTranslation) {
    var group = new H.map.Group();
  
    map.addObject(group);
  
    // add 'tap' event listener, that opens info bubble, to the group
    group.addEventListener('tap', function (evt) {
      //alert('dentro');
      // event target is the marker itself, group is a parent event target
      // for all objects that it contains
      //alert(evt.target.getPosition());
      //map.setCenter(evt.target.getPosition());
      var bubble =  new H.ui.InfoBubble(evt.target.getGeometry(), {
        // read custom data
        content: evt.target.getData()
      });
      // show info bubble
      this.ui.addBubble(bubble);
      //bubble.open();
    }, false);
  
    var json = JSON.parse( JTranslation );
    json.GetRVData.RVElementAry[0].Element.forEach(element => {
      if(element.latitudine !='') {   //only if it exist
        this.addMarkerToGroup(group, {lat:element.latitudine, lng:element.longitudine},
          '<div><a href="http://www.mcfc.co.uk" target="_blank">'+element.descrizione+'</a>' +
          '</div><div >City of Manchester Stadium<br>Capacity: 48,000</div>');
      }
  
    
    });
  }


  SearchRVLocation(location:string) {
  

    this.bvAPI.getSpecificArea(location).subscribe((data)=>{
      
      
      //let parser = require('xml2js').parseString;
      const parser = new xml2js.Parser({strict: true, trim: true });
      
      
      
      let that = this;
      parser.parseString(data, function(err,result){
        if (err) {
          console.log(err.stack);
        }
        
        var JTranslation = JSON.stringify(result);

        //and now pass the JSON string to the area object
        that.data.changeMessage(JTranslation);
        
        var json = JSON.parse( JTranslation );
         //Empty the array
         while (that.items.length>0) { that.items.pop(); }
         var areaIndex: number = 0;
         json.GetRVData.RVElementAry[0].Element.forEach(element => {
         
            var areaDaMostrare = new Itemtoshow();
            areaDaMostrare.description = element.descrizione;
            if(element.latitudine !='') {
              areaDaMostrare.indice = areaIndex;
           }
           
            areaDaMostrare.http = element.http;
            areaDaMostrare.itemtype= element.tipologia;
            areaDaMostrare.latitudine=element.latitudine;
            areaDaMostrare.lobgitudine=element.longitudine;
            areaDaMostrare.localita=element.località;
            areaDaMostrare.provincia=element.provincia;
            areaDaMostrare.tipo=element.Atipo;
            areaDaMostrare.tipologia=element.cTipo;
            areaDaMostrare.mtipo=element.mTipo;
         
            that.items.push(areaDaMostrare);

            if(element.latitudine !='') {
              //that.map.addObject(new H.map.Marker({lat:element.latitudine, lng:element.longitudine}))
              var Areaicon = new H.map.Icon(that.IconWithNumber(areaIndex));
                var AreaMarker = new H.map.Marker({
                  lat: element.latitudine,
                  lng: element.longitudine
                }, {icon: Areaicon});
                that.map.addObject(AreaMarker);
                that.PlaceHolderPlaced = true;
                
                areaIndex++;

              }

          });


          that.map.setCenter({lat:that.items[0].latitudine, lng:that.items[0].lobgitudine});
          that.map.setZoom(13);
        
         
      });

      this.ref.detectChanges();
    });

  
  }


  public ngAfterViewInit() {

    

    this.defaultLayers = this.platform.createDefaultLayers();
    this.targetElement = document.getElementById('mapContainer');
    
    
    
    this.map = new H.Map(
        this.targetElement,
        
        this.defaultLayers.vector.normal.map,
        {
            zoom: 6,
            center: {lat:41.9081836, lng:12.5794352},
            pixelRatio: window.devicePixelRatio || 1   
        }
    );

   

    // add a resize listener to make sure that the map occupies the whole container
    //window.addEventListener('resize', () => this.map.getViewPort().resize());

    //Step 3: make the map interactive - MapEvents enables the event system - Behavior implements default interactions for pan/zoom (also on mobile touch environments)
    var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));

    // Step 4: Create the default UI:
    this.ui = H.ui.UI.createDefault(this.map,this.defaultLayers);
 
    //this.route(this.start, this.finish);


    //try to switch to https
    if (location.protocol === 'http:') {
      window.location.href = location.href.replace('http://www.buonevacanze.org/RVPlanner', 'https://www.buonevacanze.org?uName=RVPlanner');
    }

  }

  /*public ngOnChanges(changes: SimpleChanges) {
    if((changes["start"] && !changes["start"].isFirstChange()) || (changes["finish"] && !changes["finish"].isFirstChange())) {
        this.route(this.start, this.finish);
       
    }
    
  } */

    
    public route() {

      
      
      //Collect the End coordinates
      var Endlat = this.routeTo.substr(6,this.routeTo.indexOf(';')-6);
      var Endlon = this.routeTo.substr(this.routeTo.indexOf(';') + 1, this.routeTo.length-Endlat.length-7);
      
      

      //Collect the Start coordinates
      var StartLat = this.myLat;
      var StartLon = this.myLon
      
      let params = {
        transportMode: this.veichle,
        representation: 'display',
        routeattributes : 'waypoints,summary,shape,legs',
        maneuverattributes: 'direction,action',
        origin: StartLat + ',' + StartLon, // Brandenburg Gate
        destination: Endlat + ',' + Endlon,  // Friedrichstraße Railway Station
        return: 'polyline,actions,travelSummary,turnByTurnActions'
      };

      /*let params = {
        "mode": "fastest;car",
        "waypoint0": "geo!" + StartLat + ',' + StartLon,
        "waypoint1": "geo!" + Endlat + ',' + Endlon,
        "representation": "display"
      }*/
      
      this.map.removeObjects(this.map.getObjects());
      this.router.calculateRoute(params, data => {
        
        this.herejason = JSON.stringify(data)
        var azione:string;
        var from:string;
        var to:string;
        var manovra:string;

        if(data) {
          
          //Empty the direction array
          while(this.directions.length>0)
            this.directions.pop();

          //alert('length:'+ data.routes[0].sections[0].turnByTurnActions.length);
          
          for (var i in data.routes[0].sections[0].turnByTurnActions){
            
            azione='';
            if (data.routes[0].sections[0].turnByTurnActions.hasOwnProperty(i)) {
              azione = data.routes[0].sections[0].turnByTurnActions[i].action;
              
              
              from = '';
              if(data.routes[0].sections[0].turnByTurnActions[i].action!= 'depart') {
                if (data.routes[0].sections[0].turnByTurnActions[i].hasOwnProperty('currentRoad')) 
                  if (data.routes[0].sections[0].turnByTurnActions[i].currentRoad.hasOwnProperty('name')) 
                    from = data.routes[0].sections[0].turnByTurnActions[i].currentRoad.name[0].value;
              }
              
              to='';
              if(data.routes[0].sections[0].turnByTurnActions[i].action!='arrive') {
                if (data.routes[0].sections[0].turnByTurnActions[i].hasOwnProperty('nextRoad'))
                  if (data.routes[0].sections[0].turnByTurnActions[i].nextRoad.hasOwnProperty('name'))
                    to= data.routes[0].sections[0].turnByTurnActions[i].nextRoad.name[0].value;
              }
              
              if((from=='')&&(to ==''))
                manovra = 'keep the direction';
              else
                manovra = azione + ' ' + from + ' to ' + to;
              

              this.directions.push(manovra);
            }
          }
          
          data.routes[0].sections.forEach((section) => {
            // Create a linestring to use as a point source for the route line
            let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
 
            // Create a polyline to display the route:
            let routeLine = new H.map.Polyline(linestring, {
              style: { strokeColor: 'blue', lineWidth: 3 }
            });
 
            // Create a marker for the start point:
            var icon = new H.map.Icon(this.svgMarkup);

            let startMarker = new H.map.Marker(section.departure.place.location, {icon: icon});
            //this.map.addObject(CamperMarker);

            //let startMarker = new H.map.Marker(section.departure.place.location);
 
            // Create a marker for the end point:
            let endMarker = new H.map.Marker(section.arrival.place.location);
 
            // Add the route polyline and the two markers to the map:
            this.map.addObjects([routeLine, startMarker, endMarker]);
 
            // Set the map's viewport to make the whole route visible:
            this.map.getViewModel().setLookAtData({bounds: routeLine.getBoundingBox()});
          });

          //you can now navigate
          this.pathDrown=true;
        }
        else
          alert('ERROR:No Route to your destination');
      }, error => {
        this.pathDrown=true;
      alert(error);
      });
    }


  public routeOLD() {

      
      
    //Collect the End coordinates
    var Endlat = this.routeTo.substr(6,this.routeTo.indexOf(';')-6);
    var Endlon = this.routeTo.substr(this.routeTo.indexOf(';') + 1, this.routeTo.length-Endlat.length-7);
    
    

    //Collect the Start coordinates
    var StartLat = this.myLat;
    var StartLon = this.myLon
    
    this.directions = [];
  
    this.router = this.platform.getRoutingService();
    
    

    var params = {
      'routingMode': 'fast',
      'transportMode': 'car',
      // The start point of the route:
      'waypoint0': "geo!" + StartLat + ',' + StartLon,
      // The end point of the route:
      'waypoint1': "geo!" + Endlat + ',' + Endlon,
      // Include the route shape in the response
      'mode' : 'fastest;car;traffic:enabled',
      'departure' : 'now',
      'return': 'polyline'
    };

    
    this.map.removeObjects(this.map.getObjects());
    
    this.router.calculateRoute(params, data => {
        alert('data:'+JSON.stringify(data));
        if(data.routes.length) {
            this.herejason=JSON.stringify(data);
            alert(this.herejason);
            /*
            this.directions = data.response.route[0].leg[0].maneuver;
            data = data.response.route[0];
            let lineString = new H.geo.LineString();
            data.shape.forEach(point => {
                let parts = point.split(",");
                lineString.pushLatLngAlt(parts[0], parts[1]);
            });
            let routeLine = new H.map.Polyline(lineString, {
                style: { strokeColor: "blue", lineWidth: 5 }
            });
            let startMarker = new H.map.Marker({
                lat: this.start.split(",")[0],
                lng: this.start.split(",")[1]
            });
            let finishMarker = new H.map.Marker({
                lat: this.finish.split(",")[0],
                lng: this.finish.split(",")[1]
            });
            this.map.addObjects([routeLine, startMarker, finishMarker]);
            this.map.setViewBounds(routeLine.getBounds());  */
            
            data.routes[0].sections.forEach((section) => {

              // Create a linestring to use as a point source for the route line
             let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
             
             this.directions.push(section.maneuver);
             
             // Create a polyline to display the route:
             let routeLine = new H.map.Polyline(linestring, {
               style: { strokeColor: 'blue', lineWidth: 3 }
             });
     
             // Create a marker for the start point:
             let startMarker = new H.map.Marker(section.departure.place.location);
     
             // Create a marker for the end point:
             let endMarker = new H.map.Marker(section.arrival.place.location);
     
             // Add the route polyline and the two markers to the map:
             this.map.addObjects([routeLine, startMarker, endMarker]);
     
             // Set the map's viewport to make the whole route visible:
             this.map.getViewModel().setLookAtData({bounds: routeLine.getBoundingBox()});
         });


            
        }
        else alert('risposta vuota');
    }, error => {
        console.error(error);
    });
}



}