import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flashing-message',
  templateUrl: './flashing-message.component.html',
  styleUrls: ['./flashing-message.component.css']
})
export class FlashingMessageComponent implements OnInit {
  FlashMessage:string;
  constructor() {
    this.FlashMessage = 'NEW: La BVChat è attiva. Effettua il login oppure richiedi subito un account gratuito.';
   }

  ngOnInit(): void {
  }

}
