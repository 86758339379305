
<mat-card class="menu-card" style="width:100%;">
    <mat-card-header>
      
      <img mat-card-avatar class="menu-header-image" src="https://www.bvrobotics.com/media/RV/CamperChat/{{items[elementtoDisplay].itemtype}}.jpg">
      <mat-card-title>{{items[elementtoDisplay].localita}}</mat-card-title>
      <mat-card-subtitle>{{items[elementtoDisplay].tipologia}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
  
        {{items[elementtoDisplay].description}}

  </mat-card-content>
  <mat-card-actions>
    <button mat-button *ngIf="items[elementtoDisplay].latitudine!=''" (click)="GetFullMap(items[elementtoDisplay].latitudine,items[elementtoDisplay].lobgitudine)">MOSTRA SU MAPPA</button>
  </mat-card-actions>
</mat-card>