
import { Component, OnInit } from '@angular/core';

import { BvroboticsapiService } from './../bvroboticsapi.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit {

  constructor(private bvAPI:BvroboticsapiService){}

  ngOnInit() {

    
    this.bvAPI.getIPAddress().subscribe((res:any)=>{


      var json = JSON.parse( res );
      //Send a log Email
      var email = 'There is a new user connected'+
                 '\n\IP:' + json.ip;
      //alert(email);
      var Observer = this.bvAPI.sendMail('vittorio.margherita@buonevacanze.org', 'New User connected on Buonevacanze.org', email).subscribe((data)=>{ /*Do nothing*/ });

    });

    


  }
} 
 