


<div class="container rounded">
    
    <div class="row" >
         <div class="col-md-12" >
             <h1>Buonevacanze Route Planner</h1><br/>
             Route Planner è un pianificatore di percorsi che potrai utilizzare per identificare il percorso migliore verso la tua destinazione considerando le dimensioni del tuo veicolo. Nella selezione del percorso potrai impostare il tipo di veicolo, le dimensioni e cercare un area di sosta alla destinazione del tuo viaggio.<br/>
             Il database delle aree di sosta di Buonevacanze.org è costantemente aggiornato dagli utenti, dagli appassionati e dalla redazione. 
        </div>

    </div>
    
    <mat-card class="menu-card" style="width:100%;">
        <button mat-button style="color: black; background-color: rgba(220,220,220,1);" (click)="GetCurrPos();">GET CURRENT POSITION</button> &nbsp;&nbsp; Lat:{{myLat}} ; Lon:{{myLon}} <br/> <br>
        <!--Current Veichle: {{veichle}}-->
        <mat-form-field class="example-full-width" >
            <mat-label>Select your veichle</mat-label>
                <select matNativeControl  id="MoveBy" name="moveBy" [(ngModel)]="veichle">
                    <option value="Car">Car</option>
                    <option value="truck">RV or Caravan</option>
                    <option value="pedestrian">By Foot</option>                    
                </select>
        </mat-form-field>
    </mat-card>
    <p></p>

    <div class="row" >
        <div class="col-md-6" >
            
                <div #map [style.width]="width" [style.height]="height" style="float: left" id="mapContainer"></div>
        </div>
        <div class="col-md-6">


            <mat-card class="menu-card" style="width:100%;">
            
                <mat-card-title class="card-container-left">Search for a parking area</mat-card-title>
                
                <form class="example-form" #f="ngForm" (ngSubmit)="onSubmit(f)" novalidate>
    
                    <mat-form-field class="example-full-width" >
                        <mat-label>Write here a location</mat-label>
                        <div class="input-group">
                        <input matInput maxlength="50" name="AreaLoc" ngModel required #AreaLoc="ngModel">
                        
                        </div>
                    </mat-form-field>
                    
                  <p></p>
                  <button mat-button style="color: white;">SEARCH</button>
                  
                  
                </form>
            </mat-card>
            <p></p>
            <mat-card class="menu-card" style="width:100%;">
                <mat-card-title class="card-container-left">Actions</mat-card-title>
                <button mat-button style="color: white;" (click)="GetNearestArea();">SEARCH PARKING AREA NEAR TO YOU</button>
                
                <button mat-button style="color: white;" (click)="ShowPosOnMap();">SHOW MY POSITION ON THE MAP</button>
                <button mat-button style="color: white;" (click)="StartNavigating();">START NAVIGATING</button>
                <button mat-button style="color: white;" (click)="StopNavigating();">STOP NAVIGATING</button>
            </mat-card>
                <br/>
         
        </div>
    </div>
    <div class="row">

        <div class="col-md-6">
            <app-rvarea></app-rvarea>
        </div>


        <div class="col-md-6">
            <mat-card class="policy-card">
                <mat-card-header>
                    <mat-card-title>Directions</mat-card-title>
                </mat-card-header>
                <mat-card-content [style.overflow]="'auto'">
                
                    <ol>
                        <li *ngFor="let direction of directions">
                            <p [innerHTML]="direction"></p>
                        </li>
                    </ol>
                </mat-card-content>
            
            </mat-card>
        </div>
    </div>
  
</div>