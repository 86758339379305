import { Itemtoshow } from './../itemtoshow';
export class Chatmessage {
  sender:string;
  txtmsg:string;
  datemsg:Date;
  groupmsg:string;
  viewitems: Itemtoshow[];
  
  
  constructor(sender:string,txtmsg:string,datemsg:Date,groupmsg:string,viewitems:Itemtoshow[]) {
    this.sender=sender;
    this.txtmsg=txtmsg;
    this.datemsg=datemsg;
    this.groupmsg=groupmsg;
    this.viewitems=viewitems;

    }   
}
