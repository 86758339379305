<p></p>

<h1 mat-dialog-title>{{data.titolo}}</h1>



<div mat-dialog-content> 
    <div class="container" style="align-content: center;">
      <img src="https://www.bvrobotics.com/media/RV/CamperChat/CamperReview/{{data.titolo + '_Main'}}.jpg" style="max-width: 100%;">
    </div>
    <p></p>

    
   

    <h2>{{data.sottotitolo}}</h2> 
    
   
    <p></p>
      
    <div style="white-space: pre-line">{{data.articolo}}</div>

    <p></p>

    <div class="container" style="align-content: center;">
      <img src="https://www.bvrobotics.com/media/RV/CamperChat/CamperReview/{{data.titolo + '_Internal'}}.jpg" style="max-width: 100%;">
    </div>
    
    <p></p>
    
    

</div>

<div mat-dialog-actions>
  <button mat-button cdkFocusInitial (click)="CloseClick()">Chiudi</button>
 
</div> 


