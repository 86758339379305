ARTICOLI DI BUONEVACANZE.ORG
<h1 mat-dialog-title>{{data.titolo}}</h1>



<div mat-dialog-content> 
    <div *ngIf="youtube != true" class="container" style="align-content: center;">
        <img  src="{{data.http}}" style="max-width: 50%;max-height: 50%;">
    </div>
    <p></p>
  
   
    <p></p>
      
    <div style="white-space: pre-line">{{data.articolo}}</div>

    <p></p>
    <tr></tr>


    <div *ngIf="youtube == true" class="panel panel-default">
      <!-- Default panel contents -->
      <div class="panel-heading"></div>
      <div class="panel-body">

 
      
          <div class="video-container">
            <iframe width="560" height="315" [src]="linkhttp" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              
              

          </div>

      </div>
   </div>   


</div>

<div mat-dialog-actions>
  <button mat-button cdkFocusInitial (click)="CloseClick()">Chiudi</button>
</div>
