
<div class="form-field-group">
    
      <mat-form-field>
         <mat-label>{{ButtonTitle}}</mat-label>
        <mat-select name="menuString">
            <mat-option *ngFor="let menuEntry of MnuElements">
                <a class="dropdown-item" (click)="dettaglio(menuEntry.id)" style="color: honeydew; text-decoration-color: indigo;"
                         href="{{menuEntry.href}}">{{menuEntry.desc}}
                </a>
            </mat-option>
         </mat-select>
       </mat-form-field>
</div>


