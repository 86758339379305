<div class="container" style="text-align: center; align-items: center;  align-content: center; width: 60%;">
   
            <a href="https://www.buonevacanze.org">
                <img src="https://www.bvrobotics.com/media/RV/CamperChat/NEWS_title.jpg" style="max-width: 60%;">
            </a> 
        
</div>
<p></p>
<div class="container rounded" >
    <app-articlepreview  [BoxID]="'ArticleList'"></app-articlepreview>
    <p></p>
</div>
<div class="container" style="text-align: center; align-items: center;  align-content: center; width: 100%;">
    <a href="http://www.bvrobotics.com">
      <img src="https://www.bvrobotics.com/media/RV/CamperChat/logo BVRobotics.png" style="max-width: 100%;">
    </a> 
  </div>
<span class="hidden" style="display: none;"> Qui troverai una selezione completa dei nostri articoli. L'elenco include l'archivio degli articoli in ordine di inserimento
</span>
