import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData{
  titolo: string;
  sottotitolo: string;
  articolo: string;
  news:boolean;
  http:string;

}


@Component({
  selector: 'app-reviewview',
  templateUrl: './reviewview.component.html',
  styleUrls: ['./reviewview.component.css']
})
export class ReviewviewComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ReviewviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    
  }
  CloseClick(): void {
    this.dialogRef.close();
  }

}
