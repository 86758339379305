
<div class="container rounded" >

    <div class="container" style="text-align: center; align-items: center;  align-content: center; width: 100%;">
      
      <img src="https://www.bvrobotics.com/media/RV/CamperChat/logo buonevacanze.png" style="max-width: 100%;">
 
    </div>
    <p></p>
    <h2 class="mat-h2">Pianifica la tua vita in plenair ... rendi il tuo viaggio unico!!</h2>
   
    <p>
       

    <p></p>
    <hr />
    <p></p>
   <app-flashing-message></app-flashing-message>
    <p></p>
   
    

    <mat-card class="menu-card">
      <mat-card-header>
        <img mat-card-avatar class="menu-header-image" src="https://www.bvrobotics.com/media/miniservices/echospot.jpg">
        <mat-card-title>Buonevacanze.org è anche su Alexa</mat-card-title>
      </mat-card-header>
      <mat-card-content>
          <p></p>
          Da oggi, sulla piattaforma Amazon è disponibile la skill Alexa collegata al database di Buonevacanze.org. La skill si chiama <a href="https://www.amazon.it/Vittorio-Margherita-sosta-camper-Buonevacanze-org/dp/B08CHJWY6G/ref=sr_1_1?brr=1&dchild=1&qid=1594900872&rd=1&s=digital-skills&sr=1-1">"Aree di sosta per camper"</a>
          e può essere installata su qualsiasi dispositivo Alexa. <br/>
          Attraverso di essa poterete ricercare, utilizzando la voce, un area di sosta in una delle località
          disponibili nel nostro database. Vi basterà dire: "Alexa cerca un area di sosta a..." seguito dalla località, meta della vostra destinazione in camper.<br/>
          <p></p>
          E' anche possibile farsi inviare via email il dettaglio di tutte le aree di sosta ricercate (necessaria autorizzazione utilizzo email di Amazon). Per fare ciò basterà
          dire: "Inviami una mail" oppure "inviami l'itinerario via email".<br/>
          <p></p>       
          La skill mette a disposizione anche un simpatico sistema di suggerimenti che può essere utilizzato per pianificare i tuoi prossimi viaggi. Prova a dire: "Suggeriscimi un itinerario" ed Alexa, 
          randomicamente, selezionerà un itinerario tra quelli disponibili proponendo tutti i dettagli del luogo di destinazione. Troverai <a href="https://www.bvrobotics.com/Buonevacanze_Alexa.html">qui</a> le istruzioni per l'uso e l'installazione
          <p></p>
          <p style="text-align:center">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/1Ekbhyx6Amk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </p>
      </mat-card-content>
    </mat-card>

    <p></p>

    
    <app-articlepreview  [BoxID]="'mainnews'" [tabName]="id2tab"></app-articlepreview>
    <p></p>  

    <mat-grid-list cols=1 rowHeight="90px" [gutterSize]="'5px'">
      <mat-grid-tile [colspan]="1" [rowspan]="1" [style.background]="'rgb(48, 46, 46)'" style="align-content: center; vertical-align: top;">

        <mat-tab-group> 
          <mat-tab label="Accesso">

            <button mat-raised-button matTooltip="Login to CamperChat to send messages to other users" [disabled]="username != null" (click)="goToUrl('https://bviotmanager.azurewebsites.net?Caller=http://www.buonevacanze.org')" aria-label="Button to manage the SignIn">
              <fa-icon id="faSignInAlt" [icon]="faSignInAlt"></fa-icon>
            </button>
            <button mat-raised-button matTooltip="Change the name of the user" aria-label="Button to manage the name change">
              <fa-icon id="faNameChange" [icon]="faNameChange"></fa-icon> 
            </button>
            <button mat-raised-button matTooltip="Cleanup list of messages" aria-label="Button to remove the list of messages" (click)="emptyCache()">
              <fa-icon id="faRemoveCache" [icon]="faRemoveCache"></fa-icon> 
            </button>

          </mat-tab>

          <mat-tab label="Facilitatori"> Content 2 </mat-tab>
          
          <mat-tab label="Uso"> 
            <button mat-raised-button matTooltip="Change the picture associated with your user" aria-label="Button to Change the picture associated with your name">
              <fa-icon id="faChangePicture" [icon]="faChangePicture"></fa-icon> 
            </button>
            
              
          </mat-tab>
        </mat-tab-group>
      </mat-grid-tile>
    </mat-grid-list>
 
</div>

<div class="container rounded">

<span class="hidden"> La chat di buonevacanze.org ha due principali caratteristiche. Anzitutto il sistema di encription denominato: "Cobra". Sviluppato da BVRobotics.com è tra i piu' sicuri disponibili. In piu' all'interno
della chat stessa sono sparsi diversi chatbot basati su AI Cobra che automaticamente possono rispondere alle domande dei partecipanti alla chat, adattando le proprie risposte alle domande 
o alle esigenze dell'utilizzatore. Certe volte non si riesce a distinguere se si parla con un partecipante alla community o se si parla con un algoritmo di intelligenza artificiale.
</span>
<mat-grid-list cols=1 rowHeight="100px" [gutterSize]="'1px'">
      
<mat-grid-tile [colspan]="1" [rowspan]="1" [style.background]="'rgb(48, 46, 46)'" style="align-content: center; vertical-align: top;">
  
      <div class="panel panel-primary" id="msg_panel">
        
        <div class="card-header messaggi-titolo d-flex " style="height:95px;" >
          <div class="row">
            <div class="col-4  messaggi-titolo ">
              <div class="grp_cotainer">
                <strong>{{GroupSelected}}</strong>
              
              </div>
            </div>
            <!--align-items-center justify-content-center-->
            <div class="col-8  messaggi-titolo ">
              <div class="d-flex align-content">
                <img src={{SelectedGroupIcon}} class="rounded-circle user_img_msg">
                <div class="d-flex justify-content-start mb-4" style="text-align: center; align-content: center;">
            
                  <div class="msg_cotainer">
                    Messages from <strong>{{username}}</strong>
                    <span class="msg_time" style="color: black;">Logged at: {{ConnectionDate | date:'HH:mm:ss EEE'}}</span>
                  </div>
              
                </div>
              </div>
            </div>
          </div>
         </div>
      </div>
    </mat-grid-tile>
   

  </mat-grid-list> 
  






<mat-grid-list cols=1 rowHeight="600px" [gutterSize]="'1px'">
      
  <mat-grid-tile [colspan]="1" [rowspan]="1" [style.background]="'rgb(48, 46, 46)'" style="align-content: center; vertical-align: top;">
      <mat-tab-group #id2tab (selectedTabChange)="tabChanged($event)" style="max-width: 100%;">
        <mat-tab *ngFor="let group of listagroup" label={{group.name}} >
          <app-chatcomponent  #ChatDOM [username]="username" [groupselected]="GroupSelected" groupname="{{group.name}}"></app-chatcomponent>
        </mat-tab>
      </mat-tab-group>
    </mat-grid-tile>
   

  </mat-grid-list> 


<mat-grid-list cols=10 rowHeight="100px" [gutterSize]="'0px'" >
  <mat-grid-tile [colspan]="8" [rowspan]="1" [style.background]="'rgb(48, 46, 46)'" style="align-content: center; vertical-align: top; padding-left: 15px;">

    &nbsp; &nbsp; &nbsp; &nbsp;<input type="text" placeholder="Inserisci qui un messaggio per la community" class="form-control" name="chatmsg" style="padding: 10px;" [(ngModel)]="userinterfacemessage" (keyup)="$event.keyCode == 13 && sendMessage()">
            

  </mat-grid-tile>
  <mat-grid-tile [colspan]="2" [rowspan]="1" [style.background]="'rgb(48, 46, 46)'" style="align-content: center; vertical-align: top;">

    <button mat-raised-button matTooltip="Send to the chat" aria-label="Button to send the message" (click)="sendMessage()">
      <fa-icon id="faSend" [icon]="faSend"></fa-icon>
    </button>

  </mat-grid-tile>
</mat-grid-list>
</div>
<p></p>
<div class="container" style="background-color:rgb(48, 46, 46); padding-top: 10px; padding-bottom: 10px;">
  
  
  <div class="panel-body" >
    <span style="color: honeydew;"> 
      <h1><img class="rounded-circle" src="https://www.bvrobotics.com/media/RV/CamperChat/People/Robotic%20Postmaster.JPG" style="width: 60px; height: 60px;" alt="RVPlanner Image">&nbsp; &nbsp; Assistente Buonevacanze.org</h1>
      <br/>Utilizzando questo text box puoi ricercare un area di sosta, un servizio "carico scarico" od un servizio campeggio per le tue vacanze. La ricerca può essere 
    effettuata utilizzando il sistema di intelligenza artificiale "Cobra assist" sviluppato da BVRobotics technology. Utilizza una frase di senso compiuto (Es: "vorrei andare a Roma") in italiano e prova ad effettuare la ricerca 
    </span>
  </div>
  <mat-form-field class="example-full-width" style="width: 100%;color: white; padding-left : 15px;padding-top: 20px;">
    <mat-label style="color: white;">Ricerca un area di sosta, un parcheggio od un campeggio</mat-label>
    <textarea matInput placeholder="Esempio: Aree di sosta vicino Roma..." style="color: white;" [(ngModel)]="userinterfacemessage" (keyup)="$event.keyCode == 13 && SearchRVLocation(id2tab)"  ></textarea>
  </mat-form-field>
</div>

<p></p>

<div class="container" style="background-color:rgb(48, 46, 46);padding-top: 10px; padding-bottom: 10px;">
 
  <span style="color: honeydew;"> 
    <h1><img class="rounded-circle" src="https://www.bvrobotics.com/media/RVPlanner/Route.JPG" style="width: 60px; height: 60px;" alt="RVPlanner Image">&nbsp; &nbsp; Route Planner for Caravan</h1>
    <br/>Finalmente on line il Route Planner di BVRobotics. Utilizzando questa app web potrai configurare i tuoi viaggi in camper costruendo percorsi adatti al tuo veicolo abitativo. Entra nelle pag di route Planner
    e sfrutta il database di Buonevacanze.org per configurare i tuoi percorsi  
  </span>
  <p></p>
  <button class="btn btn-primary btn-lg btn-block" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="gotoRoutePlanner()">
    Vai a Route Planner
  </button>
  
</div>

<p></p>

<p></p>

<div class="container" style="background-color:rgb(48, 46, 46);padding-top: 10px; padding-bottom: 10px;">
  <span style="color: honeydew;"> 
    <h1>Le nostre schede località</h1>
    <app-placetovisit></app-placetovisit>
  </span>
</div>

<p></p>

<p></p>

<div class="container" style="background-color:rgb(48, 46, 46);padding-top: 10px; padding-bottom: 10px;">
  <span style="color: honeydew;"> 
    <h1>Le nostre Review di Camper ed accessori</h1>
    <app-camperreview></app-camperreview>
  </span>
</div>

<p></p>

<p></p>
<div class="container" style="background-color:rgb(48, 46, 46);padding-top: 10px; padding-bottom: 10px;">
  <span style="color: honeydew;"> 
    <h1>In Evidenza</h1>
    <mat-grid-list cols={{breakpoint}} rowHeight="300px" [gutterSize]="'1px'">
    
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <mat-card class="menu-card" style="width:100%">
          <mat-card-header>
            <div mat-card-avatar class="menu-header-image"></div>
            <mat-card-title>Useful Collection</mat-card-title>
            <mat-card-subtitle>From BVRobotics website</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
    
            <app-button-menu-top-center [MnuElements]="listamenuleft" [ButtonTitle]="leftbuttontitle"></app-button-menu-top-center>
            <p></p>
 
            <app-button-menu-top-center [MnuElements]="listamenucenter" [ButtonTitle]="centerbuttontitle"></app-button-menu-top-center>
            <p></p>

            <app-button-menu-top-center [MnuElements]="listamenuright" [ButtonTitle]="rightbuttontitle"></app-button-menu-top-center>
  
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1" style="text-align: left;vertical-align: 0%;">
      
        <app-aree-news></app-aree-news>
      </mat-grid-tile>
  

      <mat-grid-tile [colspan]="1" [rowspan]="1">
          <app-articlepreview  (SearchFromChild)="SearchFromChild($event)" [BoxID]="'centerleft'" [tabName]="id2tab"></app-articlepreview>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
          <app-articlepreview (SearchFromChild)="SearchFromChild($event)" [BoxID]="'centerright'" [tabName]="id2tab"></app-articlepreview>
      </mat-grid-tile>
    </mat-grid-list>
  </span>
</div>
<div class="container rounded" style="background-color:rgb(48, 46, 46)">
  <p></p>
  <h2 class="mat-h2" style="color: white;">Proponi un area alla community</h2>
  <app-addyourarea></app-addyourarea>
</div>
<p></p>


    <div class="container" style="text-align: center; align-items: center;  align-content: center; width: 100%;">
      <a href="http://www.bvrobotics.com">
        <img src="https://www.bvrobotics.com/media/RV/CamperChat/logo BVRobotics.png" style="max-width: 100%;">
      </a> 
    </div>
