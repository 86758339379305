import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';



declare var ol: any;   //Variable used to display the map


export interface DialogData{
  titolo: string;
  sottotitolo: string;
  articolo: string;
  latitudine: string;
  longitudine: string;
  news:boolean;
  http:string;
  httptesto:string;

}



@Component({
  selector: 'app-locationdetails',
  templateUrl: './locationdetails.component.html',
  styleUrls: ['./locationdetails.component.css']
})

export class LocationdetailsComponent implements OnInit {

  map: any;
  linkhttp:SafeResourceUrl;
  youtubePresent:boolean;

  constructor(
    private dialogRef: MatDialogRef<LocationdetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sanitizer: DomSanitizer) {}
    
    ngOnInit(): void {

      if(/youtube/i.test(this.data.httptesto) == true) {
        this.youtubePresent = true;
        this.linkhttp = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.httptesto);
      }
      else
        this.youtubePresent = false;
      
      var mousePositionControl = new ol.control.MousePosition({
        coordinateFormat: ol.coordinate.createStringXY(4),
        projection: 'EPSG:4326',
  
        // comment the following two lines to have the mouse position
        // be placed within the map.
        className: 'custom-mouse-position',
        target: document.getElementById('mouse-position'),
        undefinedHTML: '&nbsp;'
  
      });
  
      this.map = new ol.Map({
        target: 'map',
        controls: ol.control.defaults({
          attributionOptions: {
            collapsible: false
          }
        }).extend([mousePositionControl]),
        layers: [
          new ol.layer.Tile({
            source: new ol.source.OSM()
          })
        ],
        view: new ol.View({
          center: ol.proj.fromLonLat([parseFloat(this.data.longitudine),parseFloat(this.data.latitudine)]),
          
          zoom: 13
        })
      });
  
      this.map.on('click', function (args) {
        console.log(args.coordinate);
        var lonlat = ol.proj.transform(args.coordinate, 'EPSG:3857', 'EPSG:4326');
        console.log(lonlat);
        var lon = lonlat[0];
        var lat = lonlat[1];
        //alert(`lat: ${lat} long: ${lon}`);
      });
      //alert(this.data.latitude + ':' + this.data.longitude);
      //this.addPoint(parseFloat(this.data.latitudine),parseFloat(this.data.longitudine));
      
    }  

  
  CloseClick(): void {
    this.dialogRef.close();
  }
  
    moveToCenter() {

      var view = this.map.getView();
  
      view.setCenter(ol.proj.fromLonLat([parseFloat(this.data.longitudine), parseFloat(this.data.latitudine)]));
  
      view.setZoom(15);
      //ol.marker({lon:parseFloat(this.data.longitude), lat:parseFloat(this.data.latitude)}).bindPopup('The center of the world').addTo(this.map);
  
      
    }

}
