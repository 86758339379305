<nav class="navbar navbar-dark bg-dark fixed-top ">
    <a class="navbar-brand" href="https://www.buonevacanze.org">Buonevacanze.org - Caravan, RV, Camper community</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
                <a class="nav-link" href="http://www.buonevacanze.org">{{link_menu_1}}</a>
            </li>
            <li class="nav-item">
                <a routerLink="/RVPlanner" routerLinkActive="active">Route Planner</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="https://bviotmanager.azurewebsites.net?Caller=http://www.buonevacanze.org">{{link_menu_2}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="https://bviotmanager.azurewebsites.net/">{{link_menu_3}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="http://bvrobotics.com/about.html">{{link_menu_4}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="mailto:vittorio.margherita@buonevacanze.org">{{link_menu_5}}</a>
            </li>
        </ul>
        
    </div>
</nav>