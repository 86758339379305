import { LocationdetailsComponent } from './../locationdetails/locationdetails.component';
import { Articles } from './../../articles';
import { CompletearticleComponent } from './../completearticle/completearticle.component';
import { Component, OnInit, Input, Output, EventEmitter, Inject} from '@angular/core';

import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

import { MatTabGroup } from '@angular/material/tabs';
import { Subscription, timer } from 'rxjs';
import { switchMap, take} from 'rxjs/operators';
import { BvroboticsapiService } from './../../bvroboticsapi.service';
import { DOCUMENT, Location } from '@angular/common';
import * as xml2js from 'xml2js';








@Component({
  selector: 'app-articlepreview',
  templateUrl: './articlepreview.component.html',
  styleUrls: ['./articlepreview.component.css']
})
export class ArticlepreviewComponent implements OnInit {

  
  
  subscription: Subscription;   //This is for the timer
  subResult:string;
  titolo:string;
  sottotitolo:string;
  articolo:string;
  articoloBreve: string;
  CodArticolo:string;
  configCompleted:boolean = false;
  isShowed:boolean=false;
  IsNews:boolean=false;
  ArticlesList: Articles[] = [];
  

  
  
  
  SenderName:string;
  
  @Input() BoxID:string;
  @Input() tabName:MatTabGroup;

  @Output() SearchFromChild = new EventEmitter<string>();

  constructor(private dialog: MatDialog, private bvAPI:BvroboticsapiService, @Inject(DOCUMENT) private document: Document) {
    

    this.titolo = 'Configuration ...';
    this.sottotitolo = 'Configuration ...';
    this.articolo = 'Configuration ...';
    this.articoloBreve = 'Configuration ...';
    

   }

  ngOnInit(): void {

/*
    if(this.BoxID=='mainnews')
      var url = 'approver/GetRVData.aspx?Action=Article&ID=NWRandom';
    else
      var url = 'approver/GetRVData.aspx?Action=Article&ID=Random'; 
    var uri = encodeURI(url);
    
*/


if ((this.BoxID == 'mainnews')|| (this.BoxID == 'centerleft')||(this.BoxID == 'centerright')) {
  this.bvAPI.manageArticleConfiguration(this.BoxID).subscribe((data)=>{
      
      
    //let parser = require('xml2js').parseString;
    const parser = new xml2js.Parser({strict: true, trim: true });
    let that = this;
    parser.parseString(data, function(err,result){
      if (err) {
        console.log(err.stack);
      }
    
      var JTranslation = JSON.stringify(result);
      //alert(JTranslation);
      var json = JSON.parse( JTranslation );

      var tipologia = json.GetRVData.element[0].tipologia;
      if (tipologia=='NW')
        that.IsNews=true;
      
      that.articoloBreve = json.GetRVData.element[0].descrizione;
      that.CodArticolo = json.GetRVData.element[0].codice;
      that.titolo=json.GetRVData.element[0].località;
      that.sottotitolo=json.GetRVData.element[0].provincia;
    
      that.configCompleted=true;
    });

  });
}

else {
  this.bvAPI.getArticleList().subscribe((data)=>{
      
      
    //let parser = require('xml2js').parseString;
    const parser = new xml2js.Parser({strict: true, trim: true });
    let that = this;
    parser.parseString(data, function(err,result){
      if (err) {
        console.log(err.stack);
      }
    
      var JTranslation = JSON.stringify(result);
      //alert(JTranslation);
      var json = JSON.parse( JTranslation );
    
      json.GetRVData.RVElementAry[0].Element.forEach(element => {
      
      
          var article = new Articles();
          article.articoloBreve = element.Desc;
          article.CodArticolo = element.cod;
          article.titolo=element.loc;
          article.sottotitolo=element.prov;
          that.ArticlesList.push(article);

        });
    
      that.configCompleted=true;
    });

  });
}


}


  
  goToUrl(uri): void {
  this.document.location.href = uri;
  
  }

  gotoArticles() {
    this.goToUrl("https://www.buonevacanze.org/?uName=Articles");
   }

  GetFullArticle() {
       

    this.bvAPI.getSpecificArticle(this.CodArticolo).subscribe((data)=>{
      
      
      //let parser = require('xml2js').parseString;
      const parser = new xml2js.Parser({strict: true, trim: true });
      
      
      
      let that = this;
      parser.parseString(data, function(err,result){
        if (err) {
          console.log(err.stack);
        }
        
        var JTranslation = JSON.stringify(result);
        
        var json = JSON.parse( JTranslation );
        
        const dialogConfig = new MatDialogConfig();
            dialogConfig.height = '900px';
            dialogConfig.data = {titolo: json.GetRVData.element[0].località, 
                                sottotitolo: json.GetRVData.element[0].provincia, 
                                articolo: json.GetRVData.element[0].descrizione,
                                latitudine: json.GetRVData.element[0].latitudine,
                                longitudine: json.GetRVData.element[0].longitudine,
                                news: that.IsNews,
                                http:json.GetRVData.element[0].http,
                                httptesto:json.GetRVData.element[0].httptesto}
                                if(that.IsNews) {
                                    let dialogRef = that.dialog.open(CompletearticleComponent, dialogConfig);
                                  }
                                else
                                  {
                                    let dialogRef = that.dialog.open(LocationdetailsComponent, dialogConfig);
                                  }
    
        
         
      });
    
     
    });
    
   

  }

  GetDetailArticle(id:string) {
       

    this.bvAPI.getSpecificArticle(id).subscribe((data)=>{
      
      
      //let parser = require('xml2js').parseString;
      const parser = new xml2js.Parser({strict: true, trim: true });
      
      
      
      let that = this;
      parser.parseString(data, function(err,result){
        if (err) {
          console.log(err.stack);
        }
        
        var JTranslation = JSON.stringify(result);
        //alert(JTranslation);
        var json = JSON.parse( JTranslation );
    
        const dialogConfig = new MatDialogConfig();
            dialogConfig.height = '900px';
            dialogConfig.data = {titolo: json.GetRVData.element[0].località, 
                                sottotitolo: json.GetRVData.element[0].provincia, 
                                articolo: json.GetRVData.element[0].descrizione,
                                latitudine: json.GetRVData.element[0].latitudine,
                                longitudine: json.GetRVData.element[0].longitudine,
                                news: that.IsNews,
                                http:json.GetRVData.element[0].http}
                                let dialogRef = that.dialog.open(CompletearticleComponent, dialogConfig);
                                
    
        
         
      });
    
     
    });
    
   

  }

  SearchRVLocation(location:string) {
    
         
    this.SearchFromChild.emit(location);

    this.tabName.selectedIndex = 1; //move to Aree Sosta

    alert("Se presente, il risultato verrà visualizzato nell'area chat in alto");
  
  }
  

}

