//import { DialogData } from './../articleview/articleview.component';

import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

declare var ol: any;   //Variable used to display the map

export interface MapDialogData {
  latitude: string;
  longitude: string;

}
@Component({
  selector: 'app-mapdialog',
  templateUrl: './mapdialog.component.html',
  styleUrls: ['./mapdialog.component.css']
})
export class MapdialogComponent implements OnInit {

  map: any;

  constructor(public dialogRef: MatDialogRef<MapdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MapDialogData) {}

    ngOnInit(): void {
      var mousePositionControl = new ol.control.MousePosition({
        coordinateFormat: ol.coordinate.createStringXY(4),
        projection: 'EPSG:4326',
  
        // comment the following two lines to have the mouse position
        // be placed within the map.
        className: 'custom-mouse-position',
        target: document.getElementById('mouse-position'),
        undefinedHTML: '&nbsp;'
  
      });
  
      this.map = new ol.Map({
        target: 'map',
        controls: ol.control.defaults({
          attributionOptions: {
            collapsible: false
          }
        }).extend([mousePositionControl]),
        layers: [
          new ol.layer.Tile({
            source: new ol.source.OSM()
          })
        ],
        view: new ol.View({
          center: ol.proj.fromLonLat([parseFloat(this.data.longitude),parseFloat(this.data.latitude)]),
          
          zoom: 15
        })
      });
  
      this.map.on('click', function (args) {
        console.log(args.coordinate);
        var lonlat = ol.proj.transform(args.coordinate, 'EPSG:3857', 'EPSG:4326');
        console.log(lonlat);
        var lon = lonlat[0];
        var lat = lonlat[1];
        //alert(`lat: ${lat} long: ${lon}`);
      });
      //alert(this.data.latitude + ':' + this.data.longitude);
      this.addPoint(parseFloat(this.data.latitude),parseFloat(this.data.longitude));
    }  

  CloseClick(): void {
    this.dialogRef.close();
  }

  moveToCenter() {

    var view = this.map.getView();

    view.setCenter(ol.proj.fromLonLat([parseFloat(this.data.longitude), parseFloat(this.data.latitude)]));

    view.setZoom(15);
    //ol.marker({lon:parseFloat(this.data.longitude), lat:parseFloat(this.data.latitude)}).bindPopup('The center of the world').addTo(this.map);

    
  }

  addPoint(lat: number, lng: number) {
    var vectorLayer = new ol.layer.Vector({
      source: new ol.source.Vector({
        features: [new ol.Feature({
          geometry: new ol.geom.Point(ol.proj.transform([lng, lat], 'EPSG:4326', 'EPSG:3857')),
        })]
      }),
      style: new ol.style.Style({
        image: new ol.style.Icon({
          anchor: [0.5, 0.5],
          anchorXUnits: "fraction",
          anchorYUnits: "fraction",
          src: "https://bvrobotics.com/media/rv/camperchat/pholder.png"
        })
      })
    });
    this.map.addLayer(vectorLayer);
    }

}
