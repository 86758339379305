<mat-grid-list cols={{breakpoint}} rowHeight="90px" [gutterSize]="'10px'">
    <mat-grid-tile [colspan]="1" [rowspan]="1" *ngFor="let item of items"> 
        <mat-card class="menu-card" style="width:100%;" (click)="GetFullArticle(item.codice)">
            <mat-card-header>
      
                <img mat-card-avatar class="menu-header-image"  src="https://www.bvrobotics.com/media/RV/CamperChat/localita/{{item.localita + '_main'}}.jpg">
                <mat-card-title>{{item.localita}}</mat-card-title>
                <mat-card-subtitle>{{item.provincia}}</mat-card-subtitle>
            </mat-card-header>
            
        </mat-card>
    </mat-grid-tile>
</mat-grid-list>
