import { LocationdetailsComponent } from './../locationdetails/locationdetails.component';
import { OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ChatcomponentComponent } from '../chatcomponent/chatcomponent.component';

import { GroupElement } from '../group-element';
import { Chatmessage } from '../chatmessage';
import { Menucomponents } from '../model/menucomponents';
import { Component, Inject, ViewChildren, QueryList } from '@angular/core';
import { MENUELEMENTS, GROUPELEMENTS } from '../../dati/simulatoremenu';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { ReviewviewComponent } from '../reviewview/reviewview.component';
import { CompletearticleComponent } from './../completearticle/completearticle.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

import { HttpParams } from '@angular/common/http';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { faAmericanSignLanguageInterpreting } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { DOCUMENT, Location } from '@angular/common';
import { MatTabGroup } from '@angular/material/tabs';
import { BvroboticsapiService } from './../../bvroboticsapi.service';
import * as xml2js from 'xml2js';
import { Itemtoshow } from './../../itemtoshow';



@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.css']
})
export class WebsiteComponent implements OnInit, AfterViewInit {

  
  listamenuleft : Menucomponents [] = [];
  listamenucenter : Menucomponents [] = [];
  listamenuright : Menucomponents [] = [];
  listagroup: GroupElement[] = [];
  leftbuttontitle :string;
  centerbuttontitle :string;
  rightbuttontitle :string;
  username :string;
  initialLocation:string = 'UNDEF';
  initialArticle:string = 'UNDEF';
  initialReview:string = 'UNDEF';
  initialNews:string = 'UNDEF'
  GroupSelected: string;
  SelectedGroupIcon:string;
  faSignInAlt = faSignInAlt;
  faNameChange = faAmericanSignLanguageInterpreting;
  faSend = faPaperPlane;
  faRemoveCache = faDatabase;
  faChangePicture = faImage;
  breakpoint:number = 2;
  
  
  @ViewChildren(ChatcomponentComponent) ChatDOMS: QueryList<ChatcomponentComponent>
  //@ViewChild(ChatcomponentComponent) ChatDOM!: ChatcomponentComponent;
  @ViewChild('id2tab') private id2tab: MatTabGroup

  public ConnectionDate: Date = new Date();
  

  var1: string;
  var2: string;


  //list of messages and message
  //msgtodisplay:Chatmessage;
   messages: Chatmessage[] = [];

  userinterfacemessage:string;
  
  //app title
  title = 'Buonevacanze.org';
  

  constructor(private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private bvAPI:BvroboticsapiService,
    @Inject(DOCUMENT) private document: Document) { 
     
    
    this.ConnectionDate = new Date();
    

    //assign a title to buttons
    this.leftbuttontitle = 'Useful links';
    this.centerbuttontitle = 'How To...';
    this.rightbuttontitle ='Articles';

    //Add all menu elements into the menu

    GROUPELEMENTS.forEach(element => {
      
      //The default selected element is mainGroup
      if(element.name == "Main Group") {
        element.selected = true;
        this.GroupSelected = element.name;
        this.SelectedGroupIcon = element.url;
      }
      this.listagroup.push(element); 
    })


    MENUELEMENTS.forEach(element => {
      if(element.idMenu==0) {
        this.listamenuleft.push(element);
      }
      else if(element.idMenu==1) {
        this.listamenucenter.push(element);
      }
      else if(element.idMenu==2) {
        this.listamenuright.push(element);
      }
    });
    
  }

  getParamValueQueryString( paramName ) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }
  

  emptyCache() {
    
    GROUPELEMENTS.forEach(element => {
        localStorage.removeItem('Messages'+element.name.split(' ').join('-'));
    });
    
    this.ChatDOMS.forEach(element =>{
      element.ClearMessageList();
    });
  
  }

  SearchRVLocation(tabGroup: MatTabGroup) {
    
    //In case of an empty string do nothing
    if (this.userinterfacemessage == null)
      return;

    var location = this.userinterfacemessage
    var searchMessage =  location;
    //Change the selected group
    this.GroupSelected='Aree Sosta';

    //Change the icon
    GROUPELEMENTS.forEach(element => { 
    
      if(element.name == this.GroupSelected) {
        this.SelectedGroupIcon = element.url;
      }
    })

    

   this.bvAPI.searchAreaUsingAI(searchMessage).subscribe((data)=>{
    
    //let parser = require('xml2js').parseString;
    const parser = new xml2js.Parser({strict: true, trim: true });

    let that = this;
    parser.parseString(data, function(err,result){
      if (err) {
        console.log(err.stack);
      }
    
      var JTranslation = JSON.stringify(result);
      //alert(JTranslation);
      var json = JSON.parse( JTranslation );
      var retMess = json.AIMatching.ReturnMessage;
      if (retMess == 'No match found'){
        return('No location found for your request');
      }
      else {
        var position = parseInt(json.AIMatching.Element[0].VariableElementAT,10);
        
        var location = searchMessage.substr(position,searchMessage.length-position);

        that.bvAPI.getSpecificArea(location).subscribe((data)=>{
          //let parser = require('xml2js').parseString;
          const parser = new xml2js.Parser({strict: true, trim: true });
        
          //let that = this;
          parser.parseString(data, function(err,result){
            if (err) {
              console.log(err.stack);
            }
  
            var JTranslation = JSON.stringify(result);
  
            //alert(JTranslation);
            var json = JSON.parse( JTranslation );
          
            if(json.GetRVData.ReturnMessage != 'No location found') {
              var items: Itemtoshow[] = [];
        
              json.GetRVData.RVElementAry[0].Element.forEach(element => {
                      
                var areaDaMostrare = new Itemtoshow();
                areaDaMostrare.description = element.descrizione;
                areaDaMostrare.http = element.httptesto;
                areaDaMostrare.itemtype= element.tipologia;
                areaDaMostrare.latitudine=element.latitudine;
                areaDaMostrare.lobgitudine=element.longitudine;
                areaDaMostrare.localita=element.località;
                areaDaMostrare.provincia=element.provincia;
                areaDaMostrare.tipo=element.Atipo;
                areaDaMostrare.tipologia=element.cTipo;
                areaDaMostrare.mtipo=element.mTipo;
            
                items.push(areaDaMostrare);
            
              });

              var now = new Date();
              var msgtodisplay = new Chatmessage(null,'Robotic Configurator',now,'Aree Sosta',items);
              that.ChatDOMS.forEach(element =>{
                if(element.groupname == 'Aree Sosta')
                  element.messages.push(msgtodisplay);
                  element.DecreaseConstant++;   // increase the decrese constant of this element to skip the message
              });
              
              
             
              

            }
          });
        
        });
      }
  
    });
  });


    

    this.userinterfacemessage = '';
    tabGroup.selectedIndex = 1;

  
  }

DisplayNews(id) {
  
  this.bvAPI.getSpecificArticle(id).subscribe((data)=>{
      
      
    //let parser = require('xml2js').parseString;
    const parser = new xml2js.Parser({strict: true, trim: true });
    
    
    
    let that = this;
    parser.parseString(data, function(err,result){
      if (err) {
        console.log(err.stack);
      }
      
      var JTranslation = JSON.stringify(result);
      //alert(JTranslation);
      var json = JSON.parse( JTranslation );
  
      const dialogConfig = new MatDialogConfig();
          dialogConfig.height = '900px';
          dialogConfig.data = {titolo: json.GetRVData.element[0].località, 
                              sottotitolo: json.GetRVData.element[0].provincia, 
                              articolo: json.GetRVData.element[0].descrizione,
                              latitudine: json.GetRVData.element[0].latitudine,
                              longitudine: json.GetRVData.element[0].longitudine,
                              news: true,
                              http:json.GetRVData.element[0].http}
                              let dialogRef = that.dialog.open(CompletearticleComponent, dialogConfig);
                               
  
      
       
    });
  
   
  });

}

DisplayReview(id) {      //This is to display info on a location at startup

  this.bvAPI.getSpecificArticle(id).subscribe((data)=>{
      
      
    //let parser = require('xml2js').parseString;
    const parser = new xml2js.Parser({strict: true, trim: true });
    
    
    
    let that = this;
    parser.parseString(data, function(err,result){
      if (err) {
        console.log(err.stack);
      }
      
      var JTranslation = JSON.stringify(result);
      //alert(JTranslation);
      var json = JSON.parse( JTranslation );
  
      const dialogConfig = new MatDialogConfig();
          dialogConfig.height = '900px';
          dialogConfig.data = {titolo: json.GetRVData.element[0].località, 
                              sottotitolo: json.GetRVData.element[0].provincia, 
                              articolo: json.GetRVData.element[0].descrizione,
                              
                              news: false,
                              http:json.GetRVData.element[0].http}
                              let dialogRef = that.dialog.open(ReviewviewComponent, dialogConfig);
      
       
    });
  
   
  });
  
 

}





DisplayArticle(id) {      //This is to display info on a location at startup


  this.bvAPI.getSpecificArticle(id).subscribe((data)=>{
      
      
      //let parser = require('xml2js').parseString;
      const parser = new xml2js.Parser({strict: true, trim: true });
      
      
      
      let that = this;
      parser.parseString(data, function(err,result){
        if (err) {
          console.log(err.stack);
        }
        
        var JTranslation = JSON.stringify(result);
        //alert(JTranslation);
        var json = JSON.parse( JTranslation );
    
        const dialogConfig = new MatDialogConfig();
            dialogConfig.height = '900px';
            dialogConfig.data = {titolo: json.GetRVData.element[0].località, 
                                sottotitolo: json.GetRVData.element[0].provincia, 
                                articolo: json.GetRVData.element[0].descrizione,
                                latitudine: json.GetRVData.element[0].latitudine,
                                longitudine: json.GetRVData.element[0].longitudine,
                                news: false,
                                http:json.GetRVData.element[0].http}
                                let dialogRef = that.dialog.open(LocationdetailsComponent, dialogConfig);
                                
        
         
      });
    
     
    });


}



SearchFromChild(loc) {        //This is to display a parking area at startup
    
  
      var location = loc
      var searchMessage =  location;
      //Change the selected group
      this.GroupSelected='Aree Sosta';

      //Change the icon
      GROUPELEMENTS.forEach(element => { 
  
        if(element.name == this.GroupSelected) {
          this.SelectedGroupIcon = element.url;
        }
      })


      this.bvAPI.getSpecificArea(location).subscribe((data)=>{
        //let parser = require('xml2js').parseString;
        const parser = new xml2js.Parser({strict: true, trim: true });
      
        let that = this;
        parser.parseString(data, function(err,result){
          if (err) {
            console.log(err.stack);
          }

          var JTranslation = JSON.stringify(result);

          //alert(JTranslation);
          var json = JSON.parse( JTranslation );
        
          if(json.GetRVData.ReturnMessage != 'No location found') {
            var items: Itemtoshow[] = [];
      
            json.GetRVData.RVElementAry[0].Element.forEach(element => {
                    
              var areaDaMostrare = new Itemtoshow();
              areaDaMostrare.description = element.descrizione;
              areaDaMostrare.http = element.httptesto;
              areaDaMostrare.itemtype= element.tipologia;
              areaDaMostrare.latitudine=element.latitudine;
              areaDaMostrare.lobgitudine=element.longitudine;
              areaDaMostrare.localita=element.località;
              areaDaMostrare.provincia=element.provincia;
              areaDaMostrare.tipo=element.Atipo;
              areaDaMostrare.tipologia=element.cTipo;
              areaDaMostrare.mtipo=element.mTipo;
          
              items.push(areaDaMostrare);
          
            });

            var now = new Date();
            var msgtodisplay = new Chatmessage(null,'Robotic Configurator',now,'Aree Sosta',items);
            that.ChatDOMS.forEach(element =>{
              if(element.groupname == 'Aree Sosta')
                element.messages.push(msgtodisplay);
                element.DecreaseConstant++;   // increase the decrese constant of this element to skip the message
            });
     
          }
        });
      
      });
  

}

  sendMessage() {
    if(this.userinterfacemessage.indexOf("!Set")>=0) {
      
      if(this.userinterfacemessage.indexOf("Name=")>=0)  {
        
        var oldname = this.username;
        
        var NewUserName=this.userinterfacemessage.substr(this.userinterfacemessage.indexOf("Name=") + 5 , this.userinterfacemessage.length- this.userinterfacemessage.indexOf("Name="));
        
        if(NewUserName == '') {
          alert('wrong command');
          this.userinterfacemessage = '';
        }
        else {
          
          this.username=NewUserName;
          this.userinterfacemessage = 'My old name was: ' + oldname + '. Now I changed my Name in ' + this.username;
         
        }
        
        
      }
      else {
        this.userinterfacemessage = '';
        alert('wrong command');
      }
    }
    
    if ((this.username != null)&&(this.username != '')&&(this.userinterfacemessage!='')) {

      var Observer = this.bvAPI.sendMessage(this.userinterfacemessage, this.GroupSelected, this.username).subscribe((data)=>{ /*Do nothing*/ });
    

      this.ChatDOMS.forEach(element =>{
        element.CheckMessage();
      });
      this.userinterfacemessage = '';
    }
    else{
      alert("WARNING: Wrong message or user not properly signed. Please use the login button to sign and to start sending messages");
    }
  }

tabChanged(event) {
  this.GroupSelected = event.tab.textLabel;
  GROUPELEMENTS.forEach(element => { 
    
    if(element.name == this.GroupSelected) {
      this.SelectedGroupIcon = element.url;
    }
  })
  
}


  SetGroup(grpName:string) {
    this.GroupSelected = grpName;
    
  }

  ngAfterViewInit()  {

    if (this.initialLocation !=null) {
      
      var that=this;
      setTimeout (() => {
        that.id2tab.selectedIndex=1;
        
     }, 1000);
    }

  }
  ngOnInit() {
    

  //try to switch to https
  

    this.breakpoint = (window.innerWidth <= 900) ? 1 : 2;
    
    //get the string from the input
    this.username = this.getParamValueQueryString('uName');
    this.initialLocation = this.getParamValueQueryString('location');
    this.initialArticle = this.getParamValueQueryString('article');
    this.initialReview = this.getParamValueQueryString('review');
    this.initialNews = this.getParamValueQueryString('news');
    
    //this.chatService.joinGroup('Main Group');

    if (location.protocol === 'http:') {
      window.location.href = location.href.replace('http://www.buonevacanze.org', 'https://www.buonevacanze.org');
    }


    
    if(this.username == 'RVPlanner')
      this.router.navigate(['RVPlanner'], { relativeTo: this.route });

    if(this.username == 'Articles')
      this.router.navigate(['Articles'], { relativeTo: this.route });

    if (this.initialLocation != null) {           //Parking area info
      this.SearchFromChild(this.initialLocation);

    }
    if (this.initialArticle != null) {            //Location info
      this.DisplayArticle(this.initialArticle);
  
    }
    if (this.initialReview != null) {            //Location info
      this.DisplayReview(this.initialReview);
  
    }
    if (this.initialNews != null) {            //Location info
      this.DisplayNews(this.initialNews);
  
    }
  }

goToUrl(uri): void {
    this.document.location.href = uri;
    
}

gotoRoutePlanner() {
 this.goToUrl("https://www.buonevacanze.org/?uName=RVPlanner");
}



onResize(event) {
  this.breakpoint = (event.target.innerWidth <= 900) ? 1 : 2;
}


}
