import { LocationdetailsComponent } from './../locationdetails/locationdetails.component';
import { Itemtoshow } from './../../itemtoshow';

import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';


import { BvroboticsapiService } from './../../bvroboticsapi.service';
import * as xml2js from 'xml2js';

@Component({
  selector: 'app-placetovisit',
  templateUrl: './placetovisit.component.html',
  styleUrls: ['./placetovisit.component.css']
})
export class PlacetovisitComponent implements OnInit {

  
  items: Itemtoshow[] = [];
  userinterfacemessage:string;
  breakpoint:number=3;
  
  


  constructor(public dialog:MatDialog, private bvAPI:BvroboticsapiService) {
    var DefaultItem = new Itemtoshow;
    
    DefaultItem.localita='Configuration ...';
    DefaultItem.provincia='Configuration ...';
   
    this.items.push(DefaultItem);
    
   }

   ngOnInit(): void {

    this.breakpoint = (window.innerWidth <= 900) ? 1 : 3;

    this.bvAPI.getLocationList().subscribe((data)=>{
      
      
      //let parser = require('xml2js').parseString;
      const parser = new xml2js.Parser({strict: true, trim: true });
      
      
      
      let that = this;
      parser.parseString(data, function(err,result){
        if (err) {
          console.log(err.stack);
        }
        
        var JTranslation = JSON.stringify(result);
        //alert(JTranslation);
        var json = JSON.parse( JTranslation );
    
        while (that.items.length>0) { that.items.pop(); }
        json.GetRVData.RVElementAry[0].Element.forEach(element => {
         
            var areaDaMostrare = new Itemtoshow();
            
            areaDaMostrare.localita=element.loc;
            areaDaMostrare.provincia=element.prov;
            areaDaMostrare.codice= element.cod;       //http is used as code because there is no value defined for this item
         
            that.items.push(areaDaMostrare);
           
        });
        
         
      });

     
    });

  }

  GetFullArticle(codice) {
       

    this.bvAPI.getSpecificArticle(codice).subscribe((data)=>{
      
      
      //let parser = require('xml2js').parseString;
      const parser = new xml2js.Parser({strict: true, trim: true });
      
      
      
      let that = this;
      parser.parseString(data, function(err,result){
        if (err) {
          console.log(err.stack);
        }
        
        var JTranslation = JSON.stringify(result);
        //alert(JTranslation);
        var json = JSON.parse( JTranslation );
    
        const dialogConfig = new MatDialogConfig();
            dialogConfig.height = '900px';
            dialogConfig.data = {titolo: json.GetRVData.element[0].località, 
                                sottotitolo: json.GetRVData.element[0].provincia, 
                                articolo: json.GetRVData.element[0].descrizione,
                                latitudine: json.GetRVData.element[0].latitudine,
                                longitudine: json.GetRVData.element[0].longitudine,
                                news: false,
                                http:json.GetRVData.element[0].http,
                                httptesto:json.GetRVData.element[0].httptesto}
                                let dialogRef = that.dialog.open(LocationdetailsComponent, dialogConfig);
        
         
      });
    
     
    });
    
   

  }

}
