import { GROUPELEMENTS } from './dati/simulatoremenu';

import * as io from 'socket.io-client';
import { Observable } from 'rxjs/Observable';


export class ChatService {
    
    
    private url = 'ws://buonevacanze.ddns.net:3000';
    //private url = 'http://localhost:3000';
    private groupname:string;
    private socket;  
    beating:NodeJS.Timeout;
  

    constructor() {
      
       
    }

    public connect(grpName)
    {
      
      
      this.socket = io(this.url, {query:"name=" + grpName, secure:false });
      document.addEventListener("visibilitychange", this.handleVisibilityChange, false);
    
    }

    public sendMessage(message,group,username) {
      this.socket.emit('add-' + group, '§'+username+'§:'+ message);
      
      
    }
    
    public joinGroup(group) {
      //Create a private group
      this.socket.emit('create', group);
    }


    public getMessages = (group) => {
      return Observable.create((observer) => {
          this.socket.on(group, (message) => {
              observer.next(message);
              
          });
      });

  }

  handleVisibilityChange() {
    //socket = this.socket;
    if (document.hidden) {
      
      //emit on server after every 10 seconds
      this.beating = setInterval(function () {
        
        this.socket.emit('heartbeat', { data: "a" });
        //alert('msgSent');
      }, 15000)
  
    } else {
      
      console.log("çlearing inteval beating from visiblity");
      clearInterval(this.beating);
    }
  }

} 