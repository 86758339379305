
import { DataService } from './../data.service';

import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { ButtonMenuTopCenterComponent } from './button-menu-top-center/button-menu-top-center.component';
import { FlashingMessageComponent } from './flashing-message/flashing-message.component';
import { ChatService } from './../chat.service';
import { FormsModule } from '@angular/forms';
//import { ChatitemComponent } from './chatitem/chatitem.component';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ChatcomponentComponent } from './chatcomponent/chatcomponent.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatListModule } from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { AreeNewsComponent } from './aree-news/aree-news.component';
import { ArticlepreviewComponent } from './articlepreview/articlepreview.component';
import { MapdialogComponent } from './mapdialog/mapdialog.component';
import { AddyourareaComponent } from './addyourarea/addyourarea.component';
import { CompletearticleComponent } from './completearticle/completearticle.component';
import { WebsiteComponent } from './website/website.component';
import { RvplanerComponent } from './rvplaner/rvplaner.component';
import { HereMapComponent } from './here-map/here-map.component';
import { RVareaComponent } from './rvarea/rvarea.component';
import { HttpClientModule } from '@angular/common/http';
import { PlacetovisitComponent } from './placetovisit/placetovisit.component';
import {MatSelectModule} from '@angular/material/select';
import { CamperreviewComponent } from './camperreview/camperreview.component';
import { ReviewviewComponent } from './reviewview/reviewview.component';
import { ArticlesComponent } from './articles/articles.component';
import { LocationdetailsComponent } from './locationdetails/locationdetails.component';


//import {NgbModule} from '@ng-bootstrap/ng-bootstrap';


const appRoutes: Routes = [
 // { path: 'crisis-center', component: CrisisListComponent },
 //{ path: 'CamperChat/:id', component: AppComponent },
 { path: 'RVPlanner', component:RvplanerComponent },
 { path: 'Articles', component:ArticlesComponent },
 { path: '', component:WebsiteComponent },
 { path: ':id', component:WebsiteComponent },
 
 // { path: 'heroes', component: HeroListComponent,data: { title: 'Heroes List' }},
 //{ path: '',redirectTo: '/',pathMatch: 'full'},
 { path: '**', component: PageNotFoundComponent }
 
];


@NgModule({
  declarations: [
   
    AppComponent,
    MenuComponent,
    ButtonMenuTopCenterComponent,
    FlashingMessageComponent,
    PageNotFoundComponent,
    ChatcomponentComponent,
    AreeNewsComponent,
    ArticlepreviewComponent,
    MapdialogComponent,
    AddyourareaComponent,
    CompletearticleComponent,
    WebsiteComponent,
    RvplanerComponent,
    HereMapComponent,
    RVareaComponent,
    PlacetovisitComponent,
    CamperreviewComponent,
    ReviewviewComponent,
    ArticlesComponent,
    LocationdetailsComponent  
  ],

  imports: [
    BrowserModule,
    RouterModule.forRoot(
      appRoutes
    ),
    
    FormsModule,
    FontAwesomeModule,
    MatTooltipModule,
    MatButtonModule,
    MatInputModule,
    MatTabsModule,
    MatListModule,
    MatSelectModule,
    MatGridListModule,
    MatCardModule,
    MatDialogModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  exports:[
    CommonModule],
  providers: [ChatService,
    DataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
 