
<!--*ngIf="groupselected == groupname"-->
<div class="container-fluid">
<div class="panel-body" >
    <ul class="list-group list-group-messaggi" id="messageContainer" #scrollMe [scrollTop]="scrollMe.scrollHeight">
        <li *ngFor="let message of messages; let i = index" class="list-group-item messaggi" style="padding: 1px 1px">

          <div class="card-body msg_card_body" id="msg{{i}}" *ngIf="message.viewitems==null;else viewResults">
            <div class="d-flex justify-content-start mb-4">
              <div class="img_cont_msg">
                
                    <img src="https://www.bvrobotics.com/media/RV/CamperChat/people/{{message.sender}}.jpg" onerror="this.src='https://www.bvrobotics.com/media/RV/CamperChat/people/unknown.jpg'" class="rounded-circle user_img_msg">
               
              </div>
              <div class="msg_cotainer">
                <strong>{{message.sender}}</strong>:{{message.txtmsg}}
                <span class="msg_time justify-content-start">{{message.datemsg | date:'HH:mm:ss dd/MM/yyyy'}}</span>
              </div>
            </div>
          </div>
          <ng-template #viewResults> 
          <div *ngFor="let card of message.viewitems; let j=index;">
            <div class="container-fluid">
            <div class="row">
              <div class="col-10" style="max-width:fit-content">
                <mat-card class="menu-card">
                  <mat-card-header>
                    
                        <img mat-card-avatar class="menu-header-image" src="https://www.bvrobotics.com/media/RV/CamperChat/{{card.itemtype}}.jpg">
                        <mat-card-title class="card-container-left">{{card.itemtype}}&nbsp;&nbsp;{{card.localita}}</mat-card-title>
                        <mat-card-subtitle>{{card.tipologia}}</mat-card-subtitle>
                    
                  </mat-card-header>
                  <mat-card-content>
            
                    {{card.description}} 
              
                  </mat-card-content>
               </mat-card>
               
               <span class="msg_services justify-content-start"><strong>Accomodation:</strong>{{card.tipo}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
               <span class="msg_services justify-content-start"><strong>Services:</strong>{{card.tipologia}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
               <span class="msg_services justify-content-start"><strong>Location:</strong>{{card.mTipo}}</span>

              </div>
              <div class="col-2 vertical-align" style="vertical-align:middle;">
                <button mat-fab  *ngIf="card.latitudine != ''" (click)="GetFullMap(card.latitudine,card.lobgitudine)">Map</button>
                <button mat-fab *ngIf="card.http != ''" (click)="goToUrl(card.http)" color="warn">Link</button>
                
                
              </div>
            </div>
          </div>
          </div>MA
          </ng-template>
        
          <!--<app-chatitem [uimessage]="message"></app-chatitem></strong>-->
        </li>
    </ul>
</div>
</div>