
import { Itemtoshow } from './../../itemtoshow';
import { Chatmessage } from './../chatmessage';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { MapdialogComponent } from './../mapdialog/mapdialog.component';
import { BvroboticsapiService } from './../../bvroboticsapi.service';
import * as xml2js from 'xml2js';


@Component({
  selector: 'app-aree-news',
  templateUrl: './aree-news.component.html',
  styleUrls: ['./aree-news.component.css']
})
export class AreeNewsComponent implements OnInit {

  public now: Date = new Date();
  messages: Chatmessage[] = [];
  
  items: Itemtoshow[] = [];
  userinterfacemessage:string;
  SenderName:string;
  elementtoDisplay:number;



  constructor(public dialog:MatDialog, private bvAPI:BvroboticsapiService) {
    var DefaultItem = new Itemtoshow;
    DefaultItem.localita = 'Configuration ...';
    DefaultItem.description = 'Configuration ...';
    
    DefaultItem.http = 'Configuration ...';
    DefaultItem.itemtype= 'Configuration ...';
    DefaultItem.latitudine='Configuration ...';
    DefaultItem.lobgitudine='Configuration ...';
    DefaultItem.localita='Configuration ...';
    DefaultItem.provincia='Configuration ...';
    DefaultItem.tipo='Configuration ...';
    DefaultItem.tipologia='Configuration ...';
    DefaultItem.mtipo='Configuration ...';



    this.items.push(DefaultItem);
    this.elementtoDisplay = 0;
   }

  ngOnInit(): void {

    this.bvAPI.getArea().subscribe((data)=>{
      
      
      //let parser = require('xml2js').parseString;
      const parser = new xml2js.Parser({strict: true, trim: true });
      
      
      
      let that = this;
      parser.parseString(data, function(err,result){
        if (err) {
          console.log(err.stack);
        }
        
        var JTranslation = JSON.stringify(result);
        //alert(JTranslation);
        var json = JSON.parse( JTranslation );
         //Empty the array
         while (that.items.length>0) { that.items.pop(); }

         json.GetRVData.RVElementAry[0].Element.forEach(element => {
         
            var areaDaMostrare = new Itemtoshow();
            areaDaMostrare.description = element.descrizione;
           
            areaDaMostrare.http = element.http;
            areaDaMostrare.itemtype= element.tipologia;
            areaDaMostrare.latitudine=element.latitudine;
            areaDaMostrare.lobgitudine=element.longitudine;
            areaDaMostrare.localita=element.località;
            areaDaMostrare.provincia=element.provincia;
            areaDaMostrare.tipo=element.Atipo;
            areaDaMostrare.tipologia=element.cTipo;
            areaDaMostrare.mtipo=element.mTipo;
         
            that.items.push(areaDaMostrare);

            if (this.items.length>1)
            //Generate a random number between 1 and the number of elements
              this.elementtoDisplay = Math.floor((Math.random() * this.items.length) + 1);
            else
              this.elementtoDisplay = 0;

           
         });
        
         
      });

     
    });

  }

  GetFullMap(latitude,longitude) {
    
    //send Latitude and Longitude
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {latitude: latitude, longitude: longitude}
    let dialogRef = this.dialog.open(MapdialogComponent, dialogConfig);

  }

}
