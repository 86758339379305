export class Itemtoshow {
    indice:number = 99;
    codice:string;
    itemtype:string;
    description:string;
    longDescription:string;
    localita:string;
    provincia:string;
    tipologia:string;
    latitudine:string;
    lobgitudine:string;
    tipo:string;
    http:string;
    mtipo:string;
}


