<p></p>
Le località di buonevacanze.org

<h1 mat-dialog-title>{{data.titolo}}</h1>
<p></p>


<div mat-dialog-content> 
    <div class="container" style="align-content: center;">
      <img src="https://www.bvrobotics.com/media/RV/CamperChat/localita/{{data.titolo + '_main'}}.jpg" style="max-width: 100%;">
    </div>
    <p></p>

    
   

    <h2>{{data.sottotitolo}} {{data.latitudine}} {{data.longitudine}}</h2>
    
   
    <p></p>
      
    <div style="white-space: pre-line">{{data.articolo}}</div>

    <p></p>

    Sito WEB:  <a href="{{data.http}}" target="new">{{data.http}}</a>
     <p></p>
     
     <div *ngIf="this.youtubePresent==true" class="panel panel-default">
      <!-- Default panel contents -->
      <div class="panel-heading"></div>
      <div class="panel-body">
    
    
      
          <div class="video-container">
            <iframe width="560" height="315" [src]="linkhttp" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              
              
    
          </div>
    
      </div>
    </div>  
      

</div>

<p></p>

<div class = "container" style="max-width: 800px;">
  <div id="map" class="map" style="width: 300;height: 300"></div>
</div>

<p></p>


<div mat-dialog-actions>
  <button mat-button cdkFocusInitial (click)="CloseClick()">Chiudi</button>
  <button mat-button  (click)="moveToCenter()">Posiziona al centro</button>
</div>



