import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  link_menu_1:string;
  link_menu_2:string;
  link_menu_3:string;
  link_menu_4:string;
  link_menu_5:string;

  constructor() { 
    this.link_menu_1 = 'Buonevacanze Home';
    this.link_menu_2 = 'CamperChat login';
    this.link_menu_3 = 'BVIoT Management Console';
    this.link_menu_4 = 'About BVRobotics and the author';
    this.link_menu_5 = 'Contact';
  }

  ngOnInit(): void {
  }

}
 