<mat-grid-list cols={{breakpoint}}  [gutterSize]="'10px'">
    <mat-grid-tile [colspan]="1" [rowspan]="1" *ngFor="let item of items"> 

        <div class="card" style="width: 18rem;" (click)="GetFullArticle(item.codice)">
            <img class="card-img-top" src="https://www.bvrobotics.com/media/RV/CamperChat/CamperReview/{{item.localita + '_main'}}.jpg"> alt="Card image cap">
            <div class="card-body">
              <h5 class="card-title" style="color: black;">{{item.localita}}</h5>
              <p class="card-text" style="color: black;">{{item.provincia}}</p>
            </div>
        </div>

            
       
    </mat-grid-tile>
</mat-grid-list>
